import Decimal from "decimal.js-light";
import {useHead} from "unhead";
import common from "../components/common";


let WEIGHT_G_FORMAT = "#,##0";

export const getUnitString = (unitQty:number,unit:string):string=>{
    if(!unitQty){
        return "";
    }
    return unitQty.format(WEIGHT_G_FORMAT) + " " + unit;
}

/**
 * 获取重量显示格式
 *
 * @param weight
 *            重量，单位g
 * @param convertToKg
 *            是否转换成Kg
 */
export const getWeightString=(weight:number, convertToKg:boolean):string=>{
    if(!weight){
        return "0";
    }
    if (convertToKg) {
        let kg = new Decimal(weight).div(new Decimal(1000));
        return kg.todp(3, Decimal.ROUND_CEIL).toNumber().format(WEIGHT_G_FORMAT);
    } else {
        return new Decimal(weight).todp(0, Decimal.ROUND_CEIL).toNumber().format(WEIGHT_G_FORMAT);
    }
}

export function setSeo(title: any,keywords: any,description: any){
    useHead({
        title:title,
        meta:[{name:"keywords",content:keywords},{name:"description",content:description}]
    })
}

/**
 * 获取相似商品区分页URL模板
 *
 */
export function getSimilarItemPageUrl(productId: any,  showMode: any, pageIndexFormat: any) {
    return `/similar/${productId}-${showMode}-${pageIndexFormat}.html`;
}

/**
 * 保存单词完整性截取字符串
 *
 * @param str
 * @param limit
 * @return
 */
export function getLimitStringByWord( str:string, limit:number) {
    if(!str) return str;
    if (str.length <= limit) return str;
    let c;
    let endIndex = 0;
    let lastCharIsSplit = false;
    for (let i=0;i<str.length;i++) {
        c = str.charAt(i);
        if (c == ' ' || c == ',' || c == '(' || c == ':') {
            if (i > limit) {
                break;
            } else if (i == limit) {
                if (!lastCharIsSplit) endIndex = i;
                break;
            }
            if (!lastCharIsSplit) {
                endIndex = i;
                lastCharIsSplit = true;
            }
        } else {
            lastCharIsSplit = false;
            if (i >= limit) break;
        }
    }
    return str.substring(0, endIndex > 0 ? endIndex : limit) + "...";
}

export function getHtml(desc:string){


    let temp:any = document.createElement("div");

    temp.innerHTML = desc;

    let output = temp.innerText||temp.textContent;

    temp = null;

    return output;

}
export function getUrlParams (name: any) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let param = window.location.search.substr(1).match(reg);
    return param && param[2];
}

export function numFilter (value:any, number:any) {
    // 截取当前数据到小数点后number位
    let realVal = parseFloat(value).toFixed(number);
    return realVal
}

/**
 * 折扣显示格式
 *
 * @param discount
 * @return
 */
export function getDiscountString(discount:number):string {
    if (discount == null || discount <= 0 || discount>1) {
        return "";
    }
    return new Decimal((1-discount)*100).todp(0,Decimal.ROUND_HALF_UP).toString();
}

export function isParseFloat(s: any) {
    /*-- 验证包含小数位的金额（允许输入整数、一位小数以及两位小数）--*/
    var reg = /^((\d{1,}\.\d{2,2})|(\d{1,}\.\d{1,1})|(\d{1,}))$/;
    return reg.test(s);
}

export function loadScript(src:any, callback:any){
    var script = document.createElement('script'),
        head = document.getElementsByTagName('head')[0];
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.src = src;
    if (script.addEventListener) {
        script.addEventListener('load', function () {
            callback();
        }, false);
    } else { // @ts-ignore
        if (script['attachEvent']) {
                // @ts-ignore
            script['attachEvent']('onreadystatechange', function () {
                    // @ts-ignore
                var target:any = window.event.srcElement;
                    if (target['readyState'] == 'loaded') {
                        callback();
                    }
                });
            }
    }
    head.appendChild(script);
}


export function getProductDetailUrl(productId: number, productName: string): string {
    if(!productName) {
        return `/p-${productId}-.html`;
    }
    const index: number = indexOf(productName,',',3);
    if (index > 0) {
        productName = productName.substring(0, index);
    }
    return `/p-${productId}-${filterUrl(getSubstring(productName, 200))}.html`;
}


/**
 * 从给定字符串中提取指定长度的子字符串。
 * 如果字符串长度小于或等于给定长度，则返回整个字符串。
 * 如果字符串长度大于给定长度，则返回字符串的前 len 个字符。
 * @param s - 待提取的字符串
 * @param len - 要提取的长度
 * @returns 提取的子字符串
 */
function getSubstring(s: string | null, len: number): string {
    // 如果输入字符串为 null，则返回空字符串
    if (s === null) {
        return '';
    }
    // 如果字符串长度小于或等于给定长度，则返回整个字符串
    if (s.length <= len) {
        return s;
    }
    // 返回字符串的前 len 个字符
    return s.substring(0, len);
}

function filterUrl(url: string): string {
    // 使用正则表达式匹配 URL 中的非字母数字字符
    const pattern = /[^0-9A-Za-z]/g;
    // 使用空格替换非字母数字字符
    url = url.replace(pattern, ' ');
    // 将 URL 去除前后空格
    let trimmedUrl = url.trim();
    // 将空格替换为连字符
    trimmedUrl = trimmedUrl.replace(/[^0-9A-Za-z]/g, '-').toLowerCase();
    // 合并连续的连字符
    const lowerCase = trimmedUrl.replace(/-+/g, '-');
    return lowerCase;
}


    /**
     * 查找字符串中第 qty 次出现的字符 tar 的位置。
     * @param str - 待查找的字符串
     * @param tar - 要查找的字符
     * @param qty - 查找字符的次数
     * @returns 第 qty 次出现的字符 tar 的位置，如果未找到则返回 -1
     */
function indexOf(str: string, tar: string, qty: number): number {
    let j = 0;

    // 遍历字符串
    for (let i = 0; i < str.length; i++) {
        const s = str.charAt(i);
        // 如果当前字符与要查找的字符相同
        if (s === tar) {
            j++;
            // 如果这是第 qty 次出现的字符
            if (j === qty) {
                return i;
            }
        }
    }
    // 如果未找到，则返回 -1
    return -1;
}

export function getProductDisplayTitle(name: string, code: string): string {
    let str = '';
    // 检查 name 是否包含逗号
    const index = name.indexOf(',');
    if (index > 0) {
        // 截取逗号之前的部分，并与 code 结合
        str = `${name.substring(0, index)} ${code}`;
    } else {
        // 直接将 name 与 code 结合
        str = `${name} ${code}`;
    }
    return str;
}

export function getPublicDir() {
    const isProd = process.env.NODE_ENV === "production"
    if(isProd) {
        return process.cwd() + '/dist/server'
    } else {
        return process.cwd() + '/public'
    }
}


/*-- 获取键盘key值 --*/
const getKeyCode = function(e) {
    var evt = e || window.event;
    return evt.keyCode || evt.which || evt.charCode;
};

export const onlyNumber = (sender: any, e: KeyboardEvent): boolean => {
    const keyCode = getKeyCode(e);
    if (keyCode === 48 || keyCode === 96) {
        if (sender.value !== "") {
            return true;
        }
    } else {
        if (
            keyCode === 8 || // Backspace
            keyCode === 9 || // Tab
            keyCode === 37 || // Left Arrow
            keyCode === 39 // Right Arrow
        ) {
            return true;
        } else {
            if (keyCode > 95 && keyCode < 106) { // Numpad 0-9
                return true;
            } else {
                if (keyCode > 47 && keyCode < 58) { // Digits 0-9
                    return true;
                }
            }
        }
    }
    return false;
};

export const onlyDecimalsAndNumber = (sender: any, e: KeyboardEvent): boolean => {
    const key = e.which || e.keyCode;
    // 允许的按键: Backspace, Delete, Tab, Enter, Arrow keys
    if (
        key === 8 || // Backspace
        key === 46 || // Delete
        key === 9 || // Tab
        key === 13 || // Enter
        key === 37 || // Left Arrow
        key === 38 || // Up Arrow
        key === 39 || // Right Arrow
        key === 40 // Down Arrow
    ) {
        return true;
    }
    // 检查是否按下 Shift 键
    if (e.shiftKey) {
        return false; // 禁止使用 Shift 键输入
    }
    // 允许数字和小数点的输入
    // 检查数字（主键盘和小键盘）
    if (
        (key >= 48 && key <= 57) || // Digits 0-9
        (key >= 96 && key <= 105) || // Numpad 0-9
        key === 190 || // Period (.)
        key === 110 // Numpad Decimal (.)
    ) {
        // 检查是否已经有小数点
        if (key === 190 || key === 110) {
            if (sender.value.includes('.')) {
                return false; // 只能有一个小数点
            }
        }
        // 检查小数位数
        if (sender.value.includes('.')) {
            const decimalPart = sender.value.split('.')[1];
            if (decimalPart.length >= 1) {
                return false; // 小数部分只能有一位
            }
        }
        return true; // 允许数字和小数点输入
    }
    return false; // 其他输入禁止
}

export function scrollToElement(selectorOrElement, options = { behavior: 'smooth', block: 'start' }) {
    let element;
    // 如果传入的是选择器（字符串），使用 querySelector 获取元素
    if (typeof selectorOrElement === 'string') {
        element = document.querySelector(selectorOrElement);
    }
    // 如果传入的是 DOM 元素，直接使用
    else if (selectorOrElement instanceof HTMLElement) {
        element = selectorOrElement;
    }

    // 如果获取到元素，执行滚动
    if (element) {
        element.scrollIntoView(options);
    }
}

export function discountNumber (value) {
    // 将数字转为字符串并移除无效的零
    let realVal = value.toFixed(2); // 保留两位小数
    if (realVal.indexOf('.') > -1) {
        realVal = realVal.replace(/\.?0+$/, ''); // 去掉小数点后的无效零
    }
    return realVal;
}

export function showNoFreeShipping(){
    common.confirm(
        {title:'NO FREE SHIPPING', text: 'This item is not eligible for free shipping, you will need to pay the original price for shipping.',
            confirmText: '', cancelText:''
        }
    ).then( async () => {

    }).catch(e => {

    })
}




<template>
  <div>
    <Crumb first-crumb="page not found"></Crumb>
    <div class="errorBox">
      <dl class="errorIcon notFound" v-if="!isDetailPage">
        <dt class="fontRed">
          Page not found !
        </dt>
        <dd style="color: #EA0707;">
          The page you are attempting to access is not found
        </dd>
        <dd>
          <strong>Please try the following methods:</strong>
        </dd>
        <dd>
          &gt;&gt; Please check the URL and make sure you have typed the correct one.
        </dd>
        <dd>
          &gt;&gt; Go to cobeads
          <a href="/" class="fontRed">Home Page</a>,
          <a href="/ShoppingCart-ShoppingCart-1" class="fontRed">Shopping Cart</a>,
          <a href="/MyAccount" class="fontRed">My Account</a> according to your need.
        </dd>
        <dd>
          <strong>For more help please:</strong>
        </dd>
        <dd>
          &gt;&gt; Please email to
          <a href="mailto:Sales@cobeads.com" class="fontWeightBold">Sales@cobeads.com</a> for consultation.
        </dd>
      </dl>
      <dl class="errorIcon notFound" v-else>
        <dt class="fontRed">
          The product not found
        </dt>
        <dd style="color: #000;">
          Sorry, the product you're visiting not exist or has been eliminated.
          <br>
          Return to the
          <a href="/"><b>Home Page</b></a> for more products according to you need.
        </dd>
        <dd>
          <strong>For more help please:</strong>
        </dd>
        <dd>
          &gt;&gt; Please email to
          <a href="mailto:Sales@cobeads.com" style="color: #f94d5a;">Sales@cobeads.com</a> for consultation.
        </dd>
      </dl>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .errorBox{padding-left: 240px;}
  .notFound {padding: 20px 10px 50px 46px;}
  .notFound dd{line-height: 30px;}
  .notFound dt {font-size: 25px;}
  @media (max-width: 767px){
    .errorBox {padding:0;}
  }
</style>

<script lang="ts">

import { defineComponent } from 'vue'
import Crumb from "@/components/crumb/Crumb.vue"
import useUserStore from "@/store/user";
import {useRoute} from "vue-router";
export default defineComponent({
  components: {
    Crumb
  },
  methods: {},
  setup() {
    const userStore = useUserStore();
    const route = useRoute();
    userStore.pageNotFound = true;
    let isDetailPage = false;
    if(route.name == 'ProductDetail') {
      isDetailPage = true
    }

    return{
      isDetailPage
    }
  }
})
</script>
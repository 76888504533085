import {createVNode, render} from "vue"
import Confirm from '@/components/common/confirm/Confirm.vue'

const defaultsOptions = {
    confirmText: 'Confirm',
    cancelText: 'Cancel'
}
export default (options) => {
    options = Object.assign({}, defaultsOptions, options);

    // 创建一个dom节点
    const div = document.createElement('div')
    div.setAttribute('class', 'confirm-container')
    // 挂载到body就可以在最顶层直接控制显示隐藏
    document.body.appendChild(div)
    /**
     * Confirm有两种状态, 确认和取消
     *  确认: 返回reslove(), 给confirm组件添加确认时方法
     *  取消: reject()
     */
    return new Promise((resolve: any, reject) => {

        // 封装组件属性方法
        const submitCallback = () => {
            //调用完毕后应该清空节点
            div.remove()
            resolve()
        }

        // 封装组件属性方法
        const cancelCallback = () => {
            //清空节点
            div.remove()
            reject()
        }

        let {title,text, confirmText, cancelText} = options;

        // 在此处才创建节点并挂载属性
        const VNode = createVNode(Confirm, {
            title,
            text,
            confirmText,
            cancelText,
            submitCallback,
            cancelCallback
        })

        // 渲染节点到指定dom
        render(VNode, div)
    })
}
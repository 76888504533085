<template>
  <div class="ms-drawer-mask01 hidden-sm hidden-md" v-if="isShowLeftMenu" :style="winStyle">
    <span class="ms-drawer-back" @click="closeLeftMenuBox"></span>
  </div>

  <section class="ms-drawer01 hidden-sm hidden-md" :class="{'open': isShowLeftMenu}" :style="winStyle">
    <div class="ms-drawer01-loginInfo">
      <p class="user-face"><em></em></p>
      <p class="drawer-logined" v-if="isSignIn">
        <span class="drawer-username">{{headInfo.firstName + " " + headInfo.lastName}}</span>
      </p>
      <p class="drawer-unlogin" v-else>
        <a class="drawer-signin" href="/Customer/SignIn?q=sign">{{"header.signIn".translate()}}</a>
        <a class="drawer-join" href="/Customer/SignIn?q=reg">{{"header.register".translate()}}</a>
      </p>

      <p class="inventory">
        <span class="title">Inventory Mode</span>
        <span v-if="headerShowStockProduct" @click="showStockProduct('0')" class="icon-control on"><em></em></span>
        <span v-else @click="showStockProduct('1')" class="icon-control" ><em></em></span>
        <a class="icon-help" aria-label="help" href="/HelpCenter/HelpDetail?articleId=146" target="_blank"></a>
      </p>

    </div>
    <div class="ms-drawer01-usercenter">
      <a class="drawer-myOrder" href="/MyAccount"><i></i>{{"header.myAccount".translate()}}</a>
      <a class="drawer-cart" href="/ShoppingCart-ShoppingCart-1"><i></i>{{"header.cart".translate()}}</a>
      <a class="drawer-wishList" href="/WishList/MyWishList"><i></i>{{"header.wishList".translate()}}</a>
    </div>
    <ul class="drawer-guide">
      <li class="left-currency newNav" @click="showPhoneCurrency">
        <a>{{"header.currency".translate()}}<span>{{defCurrency.code}}</span></a>
      </li>
    </ul>
    <div class="minHelpLink">
      <dl>
        <dt class="allCategories" @click="showAllCategories" :class="{'fold': isShowAllCate}">{{"header.allCategories".translate()}}</dt>
        <dd v-show="isShowAllCate">
          <ul>
            <li>
              <a href="/wholesale-jewelry-beads/2.html">{{"header.jewelryBeads".translate()}}</a>
            </li>
            <li>
              <a href="/wholesale-jewelry-findings/3.html">{{"header.jewelryFindings".translate()}}</a>
            </li>
            <li>
              <a href="/wholesale-beading-supplies/4.html">{{"header.beadingSupplies".translate()}}</a>
            </li>
            <li>
              <a href="/wholesale-stringing-materials/5.html">{{"header.stringingMaterials".translate()}}</a>
            </li>
            <li>
              <a href="/wholesale-jewelry-watches/6.html">{{"header.jewelryWatches".translate()}}</a>
            </li>
            <li>
              <a href="/wholesale-hair-accessories-findings/551.html">{{"header.hairFindings".translate()}}</a>
            </li>
            <li>
              <a href="/wholesale-sewing-knitting/736.html">{{"header.sewingKnitting".translate()}}</a>
            </li>
            <li>
              <a href="/wholesale-office-home-garden/901.html">{{"header.homeGarden".translate()}}</a>
            </li>
          </ul>
        </dd>
      </dl>
      <dl><dt class="DiscountPolicy"><a href="/HelpCenter/HelpDetail?articleId=58&categoryId=7">{{"header.discountPolicy".translate()}}</a></dt></dl>
      <dl><dt class="DeliveryFee"><a href="/Delivery-Fee.html">{{"header.deliveryFee".translate()}}</a></dt></dl>
      <dl><dt class="RepackingService"><a href="/HelpCenter/HelpDetail?articleId=11&categoryId=4">{{"header.repackingService".translate()}}</a></dt></dl>
    </div>

    <p class="ms-button-secondary drawer-loginout" v-if="isSignIn">
      <a class="ms-button" @click="signOut" href="javascript:;">{{"header.signOut".translate()}}</a>
    </p>
  </section>
  <!--修改币种 -->
  <section class="ms-panel01 currencyWin hidden-sm hidden-md" v-if="isShowCurrencyWin" :style="winStyle">
    <div class="ms-panel-header">
      <span class="ms-panel-title">{{"header.changeCurrency".translate()}}</span>
      <span class="ms-panel-cancel" @click="hidePhoneCurrency"></span>
    </div>
    <div class="ms-panel-list">
      <ul class="panel-list">
        <li v-for="(item, index) in curList" :key="index" :id="item.id" :code="item.code" :class="{'active':item.code == defCurrency.code}">
          <a rel="nofollow" :class="item.code" @click="phoneChangeCurrency(item.code)">{{item.displayName}}</a>
        </li>
      </ul>
    </div>
  </section>
</template>


<script lang="ts" src="./LeftMenu.ts"></script>

<style scoped src="./leftMenu.less" lang="less"></style>
import {reactive, toRefs, onMounted, defineComponent, PropType, inject, onServerPrefetch} from 'vue';
import {headerController, LocationType} from "@/service/HeaderController";
import {loginController} from "@/service/LoginController";
import SeaPhoto from "./SeaPhoto.vue";
import HeaderDto from "@/service/model/header/HeaderDto";
import HeaderCustomerDto from "@/service/model/header/HeaderCustomerDto";
export default defineComponent({
    components: {SeaPhoto},
    props: {
        locationType: Number
    },
    setup(props){

        const state = reactive({
            isShowSignBox: <boolean> false,
            isShowCamBox: <boolean> false,
            isShowSearchPromp: <boolean> false,
            keyWorkList: <string[]> [],
            // 用户输入关键字
            keyword: <string> '',
            // 关键字结果列表
            searchWordResult: <string[]> [],
            // 是否显示搜索结果内容框
            isShowSearchContent:<boolean>  false,
            // 点击hide 隐藏搜索结果
            isHideLi: <boolean> false,
            headInfo:  <HeaderDto> {},
            headCustomerInfo: <HeaderCustomerDto> {cartItemCount:0,cartTotalAmount:0.00, unReadTicketQty:0},
            isSignIn: <boolean> false,
            // 样式控制
            processClass: <string> 'process'
        })

        // 判断头部样式 CartList PlaceOrder PayForOrder Complete
        let propLocType = props.locationType;
        switch (propLocType){
            case LocationType.OTHER : state.processClass = 'process'; break;
            case LocationType.CART : state.processClass = 'processCartList'; break;
            case LocationType.CHECKOUT : state.processClass = 'processPlaceOrder'; break;
            case LocationType.PAYMENT : state.processClass = 'processPayForOrder'; break;
            case LocationType.PAYSUCCESS : state.processClass = 'processComplete'; break;
            default:
                state.processClass = 'process'
        }
        state.headInfo = inject('headInfo')
        state.isSignIn = state.headInfo.customerId != null;
        state.headCustomerInfo = state.headInfo.headerCustomerDto;


        // 鼠标移入移出登录
        const hoverSign = (isShowFlag) => {
            state.isShowSignBox = isShowFlag
        }
        // 点击图搜按钮显示弹窗
        const handleSearchByCam = () => {
            state.isShowCamBox = true
        }
        // 关闭图搜弹窗
        const handleClose = () => {
            state.isShowCamBox = false
        }
        // 隐藏搜索相关
        const hideSearchPromp = () =>{
            state.isShowSearchPromp = false;
            // this.isShowSearchContent = false;
        }
        // 跳转搜索结果页
        const search = (key)=> {
            key = key.toString().replace(/<.*?>/ig, "");
            window.location.href="/ProductSearch?keyword=" + key+"&effectSearch=1";
        }
        // 显示搜索关键词
        const searchkeywordTip = async () => {
            if(state.keyword) {
                // 请求接口获取到搜索结果列表数据
                state.searchWordResult =  await headerController.searchkeywordTip(state.keyword);
                state.searchWordResult = state.searchWordResult.map((value) => value.replace(state.keyword,"<b>"+state.keyword+"</b>") );
                state.isShowSearchContent = true;
            } else {
                state.isShowSearchContent = false;
            }
        }
        //
        const handleHideLi = () => {
            state.isHideLi = ! state.isHideLi;
        }

        // 显示隐藏热销关键字 下拉框
        const showSearchPromp = async () => {
            if(state.keyword == '') {
                state.isShowSearchPromp = true;
                // 热销关键字top 10
                state.keyWorkList = await headerController.getKeyWork();
            }
        }

        // 退出登录
        const signOut = async () => {
            await loginController.signOut();
            let url = window.location.href,
                urlArr = ['/shoppingcart','/Checkout','/Order','/MyAccount','/WishList','/TicketCenter','/uploadvideo','/uploadimage','/Register-successfully'],
                flag = false;
            urlArr.forEach((item, index)=>{
                if((url.toLowerCase()).indexOf(item.toLowerCase()) > 0) {
                    flag = true;
                }
            });
            if(flag){
                location.href= '/';
            } else {
                window.location.reload();
            }
        }

        return{
            ...toRefs(state),
            hoverSign,
            handleSearchByCam,
            handleClose,
            hideSearchPromp,
            search,
            searchkeywordTip,
            handleHideLi,
            showSearchPromp,
            signOut
        }
    }

})

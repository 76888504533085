import {nextTick, onMounted, reactive, ref, toRefs} from 'vue';
import AddToCartDto from "@/service/model/cart/AddToCartDto";
import {cartController} from "@/service/CartController";
import popLogin from "@/components/common/popLogin";
import useUserStore from "@/store/user";
export default {
    name: "BuyNow",
    props: {
        pid: Number,
        qty: Number,
        dom: {},
        div: {},
        submitCallback: {
            type: Function // 确认事件
        },
        cancelCallback: {
            type: Function // 取消事件
        }
    },
    setup(props, content) {
        const fade = ref(false)

        const data = reactive({
            tipMsg: <string> '',
            item:"1 "+"item".translate(),
            unit:"1 "+"unit".translate()
        })

        const buyNow = async ()=>{
            const result:AddToCartDto = await cartController.buyNow(props.pid, props.qty);
            // 判断是否需要登录
            if(result.needLogin) {
                popLogin().then(buyNow);
            } else {
                if(result.status) {
                    location.href='/Order/CheckOut'
                }else {
                    if(result.qty>1){
                        data.item=result.qty+" "+"items".translate();
                    }
                    if(result.cartQty>1){
                        data.unit=result.cartQty+" "+"units".translate();
                    }
                    switch (result.message) {
                        case 'SHOW_ADD_SUCCESS_TIP': data.tipMsg = 'addToCart.successTip'.translate(data.item,data.unit,result.totalPrice.money());
                            break;
                        case 'SHOW_DELAY_TIP': data.tipMsg = 'addToCart.delayTip'.translate(data.item,data.unit,result.totalPrice.money());
                            break;
                        case 'SHOW_LARGE_PACKAGE_TIP': data.tipMsg = 'addToCart.largePackageTip'.translate(data.item,data.unit,result.totalPrice.money());
                            break;
                        case 'ERROR_SHOPPINGCART_PRODUCT_NOT_EXISTS': data.tipMsg = 'addToCart.produceNotExistsError'.translate();
                            break;
                        case 'ERROR_SHOPPINGCART_PRODUCT_OUT_OF_STOCK': data.tipMsg = 'addToCart.productOutOfStockError'.translate(result.allQty,result.haveQty);
                            break;
                        case 'ERROR_SHOPPINGCART_PRODUCT_NO_PRICE': data.tipMsg = 'addToCart.productNoPriceError'.translate();
                            break;
                        default:
                            data.tipMsg = result.message
                    }

                    props.submitCallback(result);

                    // 设置位置
                    setPosition(props.dom, props.div)
                }

                // 当元素渲染完毕立即过渡的动画不会触发
                setTimeout(() => {
                    fade.value = true
                }, 0)

            }
        }

        onMounted(async () => {
            buyNow();
        })

        // buy now 弹窗位置
        const setPosition = async (clickWrap, popWrap) => {
            await nextTick()
            let {width, height, top, left} = clickWrap.getBoundingClientRect()
            popWrap.style.position = "absolute"
            popWrap.style.top = top  + window.scrollY - popWrap.clientHeight - 6 + "px"
            popWrap.style.left = left + window.scrollX - ((popWrap.clientWidth- clickWrap.clientWidth)/2) + "px"
        }


        return {
            fade,
            ...toRefs(data)
        }
    }
}
<template>
  <div class="tooltip" @click.self="cancelCallback" :class="{ fade }" v-if="tipMsg">
    <template v-if="tipMsg">
      <div class="message" v-html="tipMsg">
      </div>
    </template>
    <p class="button">
      <a href="/ShoppingCart-ShoppingCart-1">{{'addToCart.viewCart'.translate()}}</a> /
      <a href="javascript:;" @click.self="cancelCallback">{{'addToCart.close'.translate()}}</a>
    </p>
  </div>
</template>

<script src="./BatchAddToCart.ts"></script>
<style src="./batchAddToCart.less" scoped lang="less"></style>
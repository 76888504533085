<template>
  <form v-if="init" class="registerForm clearfix" @submit.prevent="register" >
    <dl class="loginBox register">
      <dt class="hidden-xs title" v-if="!popCallBack">{{"register.register".translate()}}
        <span class="regTips hidden-sm hidden-xs">
            <b v-html='"register.money".translate(regCouponPrompt["5"])'></b>
        </span>
      </dt>
      <dd class="regEmail clearfix">
        <span class="loginName"><em>*</em>{{"login.email".translate()}}:</span>
        <input type="text" v-model="email" maxlength="50"
               class="loginInput" autocomplete="off"
               @blur="validField('email')"
               @input="emailSmartMatch(email)"
               @keydown.down="emailDown"
               @keydown.up="emailUp"
               @keydown.enter="emailChoose(email)"
        />
        <p class="errorTip" name="ErrorTip">{{ errors.email }}</p>
        <div class="emailTip regEmailBox" v-if="regEmail.length > 0" v-show="visibleEmailBox == true" ref="vueEmailRef">
          <p class="emailTitle">{{"register.selectEmail".translate()}}</p>
          <p class="emailVal">{{email}}</p>
          <ul class="emailUl">
            <li v-for="(item,index) in regEmail" :class="{'choose': regEmailChoose == index}" @mousedown="emailChoose(email)"  @mouseover="regEmailChoose=index">{{item}}</li>
          </ul>
        </div>
      </dd>
      <dd class="clearfix">
        <span class="loginName"><em>*</em>{{"login.password".translate()}}:</span>
        <input type="password" v-model="password" @blur="validField('password')" maxlength="20" class="loginInput" autocomplete="on" @keyup="passGrade(password)" />
        <div class="pstBox" v-show="score > 0 && password.length >= 6">
          <ul class="pst">
            <li :class="{'choosed':(score > 0)}"></li>
            <li :class="{'choosed':(score >= 35)}"></li>
            <li :class="{'choosed':(score >= 70)}"></li>
          </ul>
          <span class="weak" v-if="score > 0 && score < 35">{{"register.weak".translate()}}</span>
          <span class="weak" v-else-if="score >= 35 && score < 70">{{"register.medium".translate()}}</span>
          <span class="weak" v-else="score >= 70">{{"register.strong".translate()}}</span>
        </div>
        <p class="errorTip">{{ errors.password}}</p>
      </dd>
      <dd class="clearfix">
        <span class="loginName"><em>*</em>{{"register.rePass".translate()}}:</span>
        <input type="password" maxlength="20" v-model="repeatPassword" @blur="validField('repeatPassword')" class="loginInput" autocomplete="on"  />
        <p class="errorTip">{{errors.repeatPassword}}</p>
      </dd>
      <dd class="clearfix">
        <span class="loginName"><em>*</em>{{"register.fisrtName".translate()}}:</span>
        <input type="text" v-model="firstName" @blur="validField('firstName')" maxlength="50" class="loginInput" autocomplete="on" />
        <p class="errorTip">{{errors.firstName}}</p>
      </dd>
      <dd class="clearfix">
        <span class="loginName"><em>*</em>{{"register.lastName".translate()}}:</span>
        <input type="text" v-model="lastName" @blur="validField('lastName')" maxlength="50" class="loginInput" autocomplete="on" />
        <p class="errorTip" >{{errors.lastName}}</p>
      </dd>
      <dd class="clearfix" v-if="showRegisterVerify">
        <span class="loginName"><em>*</em>{{"login.verificationCode".translate()}}:</span>
        <input type="text" v-model="validateCode" @blur="validField('validateCode')" maxlength="4" class="loginInput valiCode" autocomplete="off" />
        <div class="verify">
          <img class="validateCode" alt="ValidateCode" :src="validateImg" @click="changeValidateCode">
        </div>
        <p class="errorTip">{{errors.validateCode}}</p>
      </dd>
      <dd class="newsTipText clearfix">
        <input type="checkbox" v-model="newsletter" class="checkbox">
        <label class="newsTip"> {{"register.subscribeNewsletter".translate()}} </label>
      </dd>
      <dd class="registerSub">
        <input type="submit" class="registerBtn" value="Register">
        <p class="loginPolicyTip">
          By continuing, you agree to our
          <a href="/HelpCenter/HelpDetail?articleId=119&categoryId=1">Terms & Conditions</a>
          and
          <a href="/HelpCenter/HelpDetail?articleId=127">Privacy Policy</a>,
          about Cookies Notice and our Interest-Based Ads Notice.
        </p>
        <div style="clear: both"></div>
        <p class="color6 popLoginElement" v-if="popCallBack">
          Already have an account?
          <a href="javascript:;" @click="toSign()">Sign in</a>
        </p>
      </dd>
      <dd class="privacy hidden-xs">
        <p v-html='"register.money".translate(regCouponPrompt["5"])'></p>
        <p> {{"register.saveCart".translate()}} </p>
        <p> {{"register.trackOrders".translate()}}</p>
      </dd>
    </dl>
    <div class="CouponBox" v-if="isRegPage">
      <p class="CouponText">{{ regCouponPrompt['3'] }}</p>
      <ul class="clearfix">
        <li v-for="item in regCoupon" :class="{'rebateBg': item.isRegCouponRebate}">
          <div><b>{{ item.isRegCouponRebate ? item.couponValue + '%' : '$'+item.couponValue }}</b></div>
          <p>{{ "register.password.forOrder".translate() }} ${{ item.minAmount }}</p>
          <span>{{ "register.password.valid".translate() }} {{item.validDate}} {{ "register.password.days".translate() }}</span>
        </li>
      </ul>
      <dl>
        <dt v-html="'register.password.terms'.translate()"></dt>
        <dd v-html="'register.password.excluding'.translate()"></dd>
        <dd v-html="'register.password.eachCoupon'.translate()"></dd>
        <dd v-html="'register.password.orderCan'.translate()"></dd>
        <dd v-html="'register.password.unavailable'.translate()"></dd>
        <dd v-html="'register.password.conditions'.translate()"></dd>
      </dl>
    </div>
  </form>

</template>

<script src="./Register.ts"></script>

<style src="./register.less" scoped lang="less"></style>
<template>
  <div class="modal-backdrop" @click.self="cancelCallback" :class="{ fade }">
    <div class="modal" :class="{ fade }">
      <div class="modal-header">
        <div><h3>{{title}}</h3></div>
        <div>
          <img src="@/assets/images/icon_del.png" class="close-img" @click.stop="cancelCallback">
        </div>
      </div>
      <div class="modal-body" v-html="text"></div>
      <div class="modal-footer">
        <button v-if="confirmText" @click.stop="submitCallback" class="btn-confirm">{{confirmText}}</button>
        <button v-if="cancelText" @click.stop="cancelCallback" class="btn-close">{{cancelText}}</button>
      </div>
    </div>
  </div>
</template>

<script src="./Confirm.ts"></script>
<style src="./confirm.less" scoped lang="less"></style>
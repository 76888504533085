<template>
  <div>
      <Crumb first-crumb="system error"></Crumb>
      <div class="errorBox">
        <dl class="errorIcon notFound">
          <dt>
            System error
          </dt>
          <dd style="color: #EA0707;">
            System error and was unable to complete your request. Please try again later. Sorry for the inconvenience.
          </dd>
          <dd>
            <strong>For more help please:</strong>
          </dd>
          <dd>
            &gt;&gt; Please email to <a href="mailto:Sales@cobeads.com">Sales@cobeads.com</a> for consultation.
          </dd>
        </dl>
      </div>
  </div>
</template>

<style lang="less" scoped>
.errorBox{padding-left: 240px;}
.notFound {background: url(https://www.pandahall.com/buyer/images/master/Warning-icon.png) no-repeat 10px 20px;padding: 20px 10px 50px 86px;}
.notFound dd{line-height: 30px;}
.notFound dt {font-size: 25px;}
@media (max-width: 767px){
  .errorBox {padding:0;}
}
</style>
<script lang="ts">

import { defineComponent } from 'vue'
import Crumb from "@/components/crumb/Crumb.vue"
import useUserStore from "@/store/user";
export default defineComponent({
  components: {Crumb},
  methods: {},
  setup() {
    const userStore = useUserStore();
    userStore.systemError = true;
  }
})

</script>
<template>
  <div class="footerWrap">
    <div class="friendWrap">
      <div class="friendBox clearfix">
        <div class="friendLink clearfix hidden-xs">
          <dl>
            <dt>{{"header.shop".translate()}}</dt>
            <dd><a rel="nofollow" href="/wholesale-jewelry-beads/2.html">{{"header.jewelryBeads".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/wholesale-jewelry-findings/3.html">{{"header.jewelryFindings".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/wholesale-beading-supplies/4.html">{{"header.beadingSupplies".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/wholesale-stringing-materials/5.html">{{"header.stringingMaterials".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/wholesale-jewelry-watches/6.html">{{"header.jewelryWatches".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/wholesale-beauty-personal-care/551.html">Beauty & Personal Care</a></dd>
            <dd><a rel="nofollow" href="/wholesale-sewing-diy-crafts/736.html">Sewing & DIY Crafts</a></dd>
            <dd><a rel="nofollow" href="/wholesale-office-home-garden/901.html">Office & Home & Garden</a></dd>
          </dl>
          <dl>
            <dt>{{"header.information".translate()}}</dt>
            <dd><a rel="nofollow" href="/HelpCenter/HelpDetail?articleId=1">{{"header.aboutUs".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/HelpCenter/HelpDetail?articleId=58">{{"header.discountPolicy".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/HelpCenter/HelpAsk">{{"header.contactUs".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/sitemap.html">{{"header.sitemap".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/HelpCenter/HelpCategory?categoryId=11">{{"header.paymentInfo".translate()}}</a></dd>
          </dl>
          <dl>
            <dt>{{"header.customerService".translate()}}</dt>
            <dd><a rel="nofollow" href="/HelpCenter/HelpCategory?categoryId=15">{{"header.shippingDelivery".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/HelpCenter/HelpDetail?articleId=127">{{"header.privacyPolicy".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/HelpCenter/HelpDetail?articleId=62">{{"header.returnPolicy".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/HelpCenter">{{"header.helpFaq".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/HelpCenter/HelpCategory?categoryId=10">{{"header.customsDuties".translate()}}</a></dd>
            <dd><a rel="nofollow" href="/HelpCenter/HelpDetail?articleId=119">{{"header.termsConditions".translate()}}</a></dd>
          </dl>
        </div>
        <div class="subscribeBox clearfix">
          <dl >
            <dt>{{"header.latestCobeads".translate()}}</dt>
            <dd class="hidden-xs">{{"header.joinUsSlogan".translate()}}</dd>
          </dl>
          <div class="serviceInf">
            <p class="subscribe clearfix">
              <input type="email" aria-label="email" class="subscribeInput txtTips" placeholder="" v-model="email" :class="{'errMsg': !checkFlag}">
              <input type="button" class="subscribeBtn redBtn" value="Subscribe" @click="addNewsLetter">
            </p>
            <p class="newsLetterTips" v-if="addEmailMsg != ''" :class=" checkFlag? 'rightMsg':'errMsg' ">{{addEmailMsg}}</p>
          </div>
          <div class="ShapeShow">
            <h3>{{"header.stayConnected".translate()}}</h3>
            <ul class="Shape clearfix">
              <li class="facebook"><a rel="nofollow noreferrer" title="Follow us on Facebook" href="https://business.facebook.com/cobeadsjewelryDIYmaking/" target="_blank"></a></li>
              <li class="instagram"><a rel="nofollow noreferrer" title="Follow us on instagram" href="https://www.instagram.com/cobeadscom/" target="_blank"></a></li>
              <li class="pinterest"><a title="Follow us on pinterest" href="https://www.pinterest.co.uk/cobeadsjewelryfingdings/_created/" rel="nofollow noreferrer" target="_blank"></a></li>
              <li class="viedo"><a title="Follow us on youtube" href="https://www.youtube.com/channel/UCdKgtz8OvZKIoBlb5SHVeCA" rel="nofollow noreferrer" target="_blank"></a></li>
              <li class="tikTok"><a title="Follow us on tikTok" href="https://www.tiktok.com/@cobeadsjewelryfindings?lang=en" rel="nofollow noreferrer" target="_blank"></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="footerMenu">
      <p class="footerContactUs"><a href="/HelpCenter/HelpAsk" title="Contact Us"></a></p>
      <p class="weeklyHotList"><a href="/weekly-hot-listing/1.html?utm_source=page_BN00_Hot" title="Weekly Hot Listing"></a></p>
      <p class="toTop" v-if="!isTop"><a href="javascript:;" class="topGoTop" @click="backtop" title="Back to Top"></a></p>
    </div>

    <div class="footerMain hidden-sm hidden-md">
      <div class="friendLink clearfix">
        <div class="minHelpLink">
          <dl>
            <dt @click="showFooterMenu(1)" :class="{'fold': nowIndex == 1}">{{"header.shop".translate()}}<em></em></dt>
            <dd v-show="nowIndex == 1">
              <ul>
                <li><a rel="nofollow" href="/wholesale-jewelry-beads/2.html">{{"header.jewelryBeads".translate()}}</a></li>
                <li><a rel="nofollow" href="/wholesale-jewelry-findings/3.html">{{"header.jewelryFindings".translate()}}</a></li>
                <li><a rel="nofollow" href="/wholesale-beading-supplies/4.html">{{"header.beadingSupplies".translate()}}</a></li>
                <li><a rel="nofollow" href="/wholesale-stringing-materials/5.html">{{"header.stringingMaterials".translate()}}</a></li>
                <li><a rel="nofollow" href="/wholesale-jewelry-watches/6.html">{{"header.jewelryWatches".translate()}}</a></li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt @click="showFooterMenu(2)" :class="{'fold': nowIndex == 2}">{{"header.information".translate()}}<em></em></dt>
            <dd v-show="nowIndex == 2">
              <ul>
                <li><a rel="nofollow" href="/HelpCenter/HelpDetail?articleId=1">{{"header.aboutUs".translate()}}</a></li>
                <li><a rel="nofollow" href="/HelpCenter/HelpDetail?articleId=58">{{"header.discountPolicy".translate()}}</a></li>
                <li><a rel="nofollow" href="/HelpCenter/HelpAsk">{{"header.contactUs".translate()}}</a></li>
                <li><a rel="nofollow" href="/sitemap.html">{{"header.sitemap".translate()}}</a></li>
                <li><a rel="nofollow" href="/HelpCenter/HelpCategory?categoryId=11">{{"header.paymentInfo".translate()}}</a></li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt @click="showFooterMenu(3)" :class="{'fold': nowIndex == 3}">{{"header.customerService".translate()}}<em ></em></dt>
            <dd v-show="nowIndex == 3">
              <ul>
                <li><a rel="nofollow" href="/HelpCenter/HelpCategory?categoryId=15">{{"header.shippingDelivery".translate()}}</a></li>
                <li><a rel="nofollow" href="/HelpCenter/HelpDetail?articleId=127">{{"header.privacyPolicy".translate()}}</a></li>
                <li><a rel="nofollow" href="/HelpCenter/HelpDetail?articleId=62">{{"header.returnPolicy".translate()}}</a></li>
                <li><a rel="nofollow" href="/HelpCenter">{{"header.helpFaq".translate()}}</a></li>
                <li><a rel="nofollow" href="/HelpCenter/HelpCategory?categoryId=10">{{"header.customsDuties".translate()}}</a></li>
                <li><a rel="nofollow" href="/HelpCenter/HelpDetail?articleId=119">{{"header.termsConditions".translate()}}</a></li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="payWay">
      <p><a class="partIcon" href="javascript:;" title="pay way"></a></p>
    </div>
    <p class="copyRight">{{copyRight}}</p>
  </div>

  <div class="tipTextBox" v-if="isShowPrivacy">
    <div class="tipText">
      <p class="clearfix">
        <label v-html='"footer.privacy".translate()'></label>
        <input class="acceptBtn rejectBtn" type="button" @click="handleClosePrivacy" value="Reject">
        <input class="acceptBtn" style="margin-right: 5px;" type="button" @click="acceptPrivacy" value="Accept">
      </p>
    </div>
  </div>


  <div class="subscriptionWrap" v-if="isShowFootSubscribe">
    <div class="subscriptionMain">
      <h2><a href="/Customer/SignIn">{{ regCouponPrompt['4'] }}</a></h2>
      <p>Subscribe to our newsletter and comment on our products to get $15 cash</p>
      <label class="jsFooterEmailBox" v-if="!checkFlag">
        <input type="email" v-model="email" class="subEmail" placeholder="Enter your email">
        <input type="button" class="subBtn" value="Submit" @click="addNewsLetter">
      </label>
      <a v-if="addEmailMsg != ''" :class=" checkFlag? 'rightTip':'errMsg' ">{{addEmailMsg}}</a>
      <span class="closeBtn" @click="handleCloseSubscribe"></span>
    </div>
  </div>
</template>
<script lang="ts" src="./Footer.ts"></script>

<style src="./footer.less" lang="less" scoped></style>
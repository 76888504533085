<template>
  <div class="modal-backdrop" v-if="modal.modalShow">
    <div class="modal" >
      <div class="modal-header">
        <div><h3 v-html="modal.title"></h3></div>
        <div v-show="modal.showClose">
          <img src="@/assets/images/icon_del.png" class="close-img" @click="confirmModal(false)">
        </div>
      </div>
      <div class="modal-body">
        <slot />
      </div>
      <div class="modal-footer">
        <button @click='confirmModal(true)' v-if="modal.showConfirmButton" class="btn-confirm">Submit</button>
        <button @click='confirmModal(false)' v-if="modal.showCancelButton" class="btn-close">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script src="./Modal.ts"></script>
<style src="./modal.less" scoped lang="less"></style>
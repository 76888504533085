
// @ts-ignore
interface String {
	format(...params) : string;
	translate(...params):string;
	equalsIgnoreCase(target:string):boolean;
	formatDate(type?: number):string;
	decodeHtml():string;
}
interface Number{
	format(pattern:string,dec_point?:string):string;
	money(currencyDto?:any):string;
	moneyWithoutRate():string;
	toFixNumber(scale:number):number;
}


String.prototype.translate = function () {
	let s:string="";
	if (s === undefined || s == null) {
		s = <string>this;
	}
	for (let i = 0; i < arguments.length; i++) {
		if (arguments[i] !== undefined && arguments[i] !== null){
			let tar = arguments[i];
			if (arguments[i].toString().indexOf("::") != -1) {
				let as = arguments[i].split("::");
				tar = "<span class='" + as[0] + "'>" + as[1] + "</span>";
			}
			s = s.replace("{" + i + "}", tar);
		}
	}
	s = s + "";
	return s;
}

String.prototype.equalsIgnoreCase = function (target:string) :boolean{
	let s = <string>this;
	if(s.toUpperCase()==target.toUpperCase()){
		return true;
	}
	return false;
}

String.prototype.decodeHtml  = function ():string{
	let s = <string>this;
	if (!s) return '';
	if(typeof DOMParser === 'undefined'){
		return s;
	}
	const parser = new DOMParser();
	const decodedString = parser.parseFromString(s, 'text/html').body.textContent || '';
	return decodedString;
}

String.prototype.format = function ( ...params) :string{
	let s = <string>this;
	if(params.length==0){
		return s;
	}
	if (params.length == 1 && params[0] instanceof Object) {
		params=params[0];
	}
	for(const [key, val] of Object.entries(params)){
		s=s.replaceAll("{"+key+"}",val);
	}
	return s;
}

/**
 * number_format
 * @param number 传进来的数,
 * @param bit 保留的小数位,默认保留两位小数,
 * @param sign 为整数位间隔符号,默认为空格
 * @param gapnum 为整数位每几位间隔,默认为3位一隔
 * @type arguments的作用：arguments[0] == number(之一)
 */
function number_format(number,bit,dec_point,sign){
	//设置接收参数的默认值
	var gapnum = 3 ;
	var str    = '' ;

	number     = number.toFixed(bit);//格式化
	let realnum    = number.split('.')[0];//整数位(使用小数点分割整数和小数部分)
	let decimal="";
	if(bit>0){
		decimal    = number.split('.')[1];//小数位
	}
	let realnumarr = realnum.split('');//将整数位逐位放进数组 ["1", "2", "3", "4", "5", "6"]

	//把整数部分从右往左拼接，每bit位添加一个sign符号
	for(var i=1;i<=realnumarr.length;i++){
		str = realnumarr[realnumarr.length-i] + str ;
		if(i%gapnum == 0){
			str = sign+str;//每隔gapnum位前面加指定符号
		}
	}
	//当遇到 gapnum 的倍数的时候，会出现比如 ",123",这种情况，所以要去掉最前面的 sign
	str = (realnum.length%gapnum==0) ? str.substr(1) : str;
	//重新拼接实数部分和小数位
	if(bit==0){
		return str;
	}
	return str+dec_point+decimal;
}

/** 时间戳转换 type==1 精确到时分秒  type==2精确到时分 */
String.prototype.formatDate = function (type) {
	let s = <string>this;
	if(!s) {
		return "-";
	}else{
		const dt = new Date(s);
		const y = dt.getFullYear()
		const m = (dt.getMonth() + 1 + '').padStart(2, '0')
		const d = (dt.getDate() + '').padStart(2, '0')

		const hh = (dt.getHours() + '').padStart(2, '0')
		const mm = (dt.getMinutes() + '').padStart(2, '0')
		const ss = (dt.getSeconds() + '').padStart(2, '0')
		if(type==1) {
			return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
		}else if(type == 2){
			return `${y}-${m}-${d} ${hh}:${mm}`
		}else{
			return `${y}-${m}-${d}`
		}
	}
}

/**
 * 修改 js number 的四舍五入方法, js的四舍五入精度计算有问题
 * @param scale 精确到小数点后面多少位
 * @returns {Number} 四舍五入后的数据
 */
Number.prototype.toFixNumber = function(scale){
	if(!scale)scale = 0;
	var thisStr = this + "";
	var dotIdx = thisStr.indexOf(".");
	if(dotIdx > 0){ //当有小数点的时候
		var offsest = thisStr.length - dotIdx - 1; //计算小数点后有多少位
		if(offsest > scale){ //位数多则进行四舍五入
			var numberPre = thisStr.substring(0, dotIdx + scale + 1);
			var bit = parseInt(thisStr.substring(dotIdx + scale + 1, dotIdx + scale + 2), 10);
			return new Number(new Number(numberPre + (bit == 5 ? bit+1:bit)).toFixed(scale));
//            } else if(offsest < scale){ //位数少则补 0
//                return new Number(thisStr.fillZero(false, scale-offsest));
		}
//        } else if(scale > 0) { //无小数点的时候根据精度补 0
//            return new Number(thisStr.fillZero(true, scale));
	}
	return this;
}

Number.prototype.format=function(pattern:string,dec_point:string="."):string{
	var n=pattern.match(/[#0]([^\.0#])[#0]{3}\.?/);
	let thousands_sep="";
	if(n!=null){
		thousands_sep=n[0][1];
	}
	pattern=pattern.replace(thousands_sep,"");
	let f = /(#*)(0+)(\.?)(0*)(#*)\s*(%?)/;
	let re =f.test(pattern);
	let isper=false;
	let zl=0;
	let xl=0;
	let xj=0;
	let s:any = <number>this;
	if(re){
		if(RegExp.$6=="%"){
			isper=true;
			s=s*100;
		}
		zl=RegExp.$2.length;
		xl=RegExp.$4.length;
		xj=RegExp.$5.length;
		let re=number_format(s,xl+xj,dec_point,thousands_sep);
		let a = s.toFixed(0).toString();
		if(zl>a.length){
			re= '0'.repeat( zl-a.length )+re;
		}
		if(xj>0){
			let index=re.length;
			for(let ind=re.length-1;ind>=re.length-xj;ind--){
				if(re[ind]!="0"){
					break;
				}
				index=ind;
			}
			re=re.substr(0,index);
			if(re.endsWith(dec_point)){
				re=re.substr(0,re.length-1);
			}
		}
		re=pattern.replace(/(#*)(0+)(\.?)(0*)(#*)/,re);
		return re;
	}
	return "######";
};










<template>
  <form name="signInForm" class="signInForm clearfix">
    <dl class="loginBox">
      <dt class="hidden-xs title" v-if="!popCallBack">{{"login.signIn".translate()}}</dt>
      <dd class="clearfix">
        <span class="loginName">{{"login.email".translate()}}:</span>
        <input type="text" v-model="email" name="email" @blur="validField('email')" maxlength="50" class="loginInput" autocomplete="on" />
        <p class="errorTip">{{ errors.email }}</p>
      </dd>
      <dd class="clearfix">
        <span class="loginName">{{"login.password".translate()}}:</span>
        <input type="password" maxlength="20" name="password" v-model="password" @blur="validField('password')" class="loginInput" autocomplete="on" />
        <p class="errorTip">{{ errors.password }}</p>
      </dd>
      <dd class="clearfix" v-if="showSignInVerify">
        <span class="loginName">{{"login.verificationCode".translate()}}:</span>
        <input type="text" class="loginInput" v-model="validateCode" @blur="validField('validateCode')"
               :class="{'errorInput': errors.validateCode != undefined}" maxlength="4" style="width: 155px;" />
        <div class="verify">
          <img class="validateCode" alt="ValidateCode" :src="validateImg" @click="changeValidateCode"/>
        </div>
        <p class="errorTip">{{errors.validateCode}}</p>
      </dd>
      <dd class="color6 clearfix">
        <input type="checkbox" v-model="remember" class="checkbox">
        <label class="newsTip">{{"login.keepLogin".translate()}}</label>
      </dd>
      <dd class="loginBtn">
        <input type="button" class="signinBtn" value="Sign In" @click="login">
        <p class="loginPolicyTip">
          By continuing, you agree to our
          <a href="/HelpCenter/HelpDetail?articleId=119&categoryId=1">Terms & Conditions</a>
          and
          <a href="/HelpCenter/HelpDetail?articleId=127">Privacy Policy</a>,
          about Cookies Notice and our Interest-Based Ads Notice.
        </p>
        <a href="/Customer/ForgottenPwd">{{"login.forgetPass".translate()}}?</a>
        <div style="clear: both"></div>
        <p class="color6 popLoginElement" v-if="popCallBack">
          Not yet a member?
          <a href="javascript:;" @click="toReg" style="text-decoration: underline">Register to get free coupons</a>
        </p>
      </dd>
    </dl>
  </form>
</template>

<script src="./Login.ts" lang="ts"></script>

<style src="./login.less" lang="less" scoped></style>
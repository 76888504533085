import {createVNode, render} from "vue"
import PopLogin from '@/components/common/popLogin/PopLogin.vue'

export default (isReg=false) => {
    // 创建一个dom节点
    const div = document.createElement('div')
    div.setAttribute('class', 'popLogin-container')
    // 挂载到body就可以在最顶层直接控制显示隐藏
    document.body.appendChild(div)
    /**
     * Confirm有两种状态, 确认和取消
     *  确认: 返回reslove(), 给confirm组件添加确认时方法
     *  取消: reject()
     */
    return new Promise((reslove: any, reject) => {

        // 封装组件属性方法
        const submitCallback = () => {
            //调用完毕后应该清空节点
            div.remove()
            reslove()
        }

        // 封装组件属性方法
        const cancelCallback = () => {
            //清空节点
            div.remove()
            reject()
        }

        // 在此处才创建节点并挂载属性
        const VNode = createVNode(PopLogin, {
            isReg:isReg,
            submitCallback,
            cancelCallback
        })

        // 渲染节点到指定dom
        render(VNode, div)
    })
}
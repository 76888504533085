import "@/utils/utils"
import en from '@/lang/en'

let message ={}
let mss = {
	en: en
}
export async function messageInit(lang){
	message = en;
}

String.prototype.translate = function () {

	let s:string=message[<string>this];
	if (s === undefined || s == null) {
		s = <string>this;
	}
	for (let i = 0; i < arguments.length; i++) {
		if (arguments[i] !== undefined && arguments[i] !== null){
			let tar = arguments[i];
			if (arguments[i].toString().indexOf("::") != -1) {
				let as = arguments[i].split("::");
				tar = "<span class='" + as[0] + "'>" + as[1] + "</span>";
			}
			s = s.replace("{" + i + "}", tar);
		}
	}
	s = s + "";
	return s;
}
import {reactive, toRefs, onMounted, defineComponent, PropType, inject} from 'vue';
import {headerController, PageLocationType} from "@/service/HeaderController";
import Cookies from 'js-cookie'
import InitSignInDto from "@/service/model/login/InitSignInDto";
import {loginController} from "@/service/LoginController";
import {getUrlParams} from "@/utils/displayUtil";
import HeaderDto from "@/service/model/header/HeaderDto";
import {useContext} from "../../Ph";
export default defineComponent({
    props:{
        pageLocation:  Number
    },
    setup(props) {
        const state = reactive({
            email: <string> '',
            addEmailMsg: <string> '',
            checkFlag: <boolean> false,
            nowIndex: <number> 0,
            copyRight: <string> '',
            isShowSubscribe: <boolean> false,
            isTop: <boolean> false,
            helpList: <any> [],
            searchHelpVal: <string> '',
            isShowHelp: <boolean> false,
            headInfo:  <HeaderDto> {},
            isSignIn: <boolean> false,
            isShowPrivacy: <boolean> false,
            isShowFootSubscribe:<boolean> false,
            regCouponPrompt:<any> null,
        })

        state.headInfo = inject('headInfo')
        state.isSignIn = state.headInfo.customerId != null;

        onMounted(async ()=>{
            window.addEventListener("scroll", showTopBtn,true);
            // 获取版权信息
            state.copyRight =  await headerController.getWebsiteCopyrightInfo();
            // 订阅框是否显示
            state.isShowSubscribe = props.pageLocation == PageLocationType.INDEX ? true : false;
            //国家是否显示隐私政策
            let isShowPolTipBox =  await headerController.isShowPolTipBox();
            // 底部隐私政策
            state.isShowPrivacy = (!(Cookies.get("AcceptPolicy") == "hide" || Cookies.get("RefusePolicy") == "hide" || state.isSignIn) && isShowPolTipBox);
            // 是否展示验证码
            let params = getUrlParams('access');
            if(!params){
                let initSignInDto: InitSignInDto = await loginController.initSignIn('');
                state.regCouponPrompt = initSignInDto.regCouponPrompt;
            }
            let isSubscribedNewsletter =  await headerController.isSubscribedNewsletter();
            state.isShowFootSubscribe = (Cookies.get("isShowFootSubscribe") == null  && !isSubscribedNewsletter && state.isSignIn);
        })

        // 展开收起底部menu
        const showFooterMenu = (index) => {

            if (state.nowIndex === index) {
                // 如果当前已经展开，则关闭
                state.nowIndex = null;
            } else {
                // 否则展开新的，折叠其他
                state.nowIndex = index;
            }
            // state.nowIndex != 0 ? state.nowIndex = 0: state.nowIndex = index;
        }
        // 验证邮箱格式是否正确
        const isEmail = (str) =>{
            let strReg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return strReg.test(str);
        }
        // 邮箱订阅事件
        const addNewsLetter = async () =>{
            if(!isEmail(state.email)) {
                state.addEmailMsg = 'Please provide a valid e-mail address.';
                state.checkFlag = false;
                return false;
            }
            await  headerController.addNewsLetter(state.email)
            state.checkFlag = true;
            state.addEmailMsg = 'Subscribe for product news successfully!';
        }

        // 显示回到顶部按钮
        const showTopBtn = () =>{
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if(scrollTop > 50) {
                state.isTop = false
            } else {
                state.isTop = true
            }
        }

        // 关闭底部隐私政策
        const acceptPrivacy = () =>{
            state.isShowPrivacy = false;
            Cookies.set("AcceptPolicy", "hide", {path: '/'});
        }

        const handleClosePrivacy = () =>{
            state.isShowPrivacy = false;
            Cookies.set("RefusePolicy", "hide", {path: '/'});
        }

        const handleCloseSubscribe = () =>{
            state.isShowFootSubscribe = false;
            Cookies.set('isShowFootSubscribe', false);
        }

        const backtop = () =>{
            var timer = setInterval(function(){
                let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                state.isTop = true;
                if(osTop === 0){
                    clearInterval(timer);
                }
            },30)
        }

        // 显示弹窗
       const openHelpWin = ()=> {
           state.isShowHelp = true;
        }

        // 关闭弹窗
        const closeHelpWin = ()=>{
            state.isShowHelp = false;
        }

        return {
            ...toRefs(state),
            showFooterMenu,
            isEmail,
            showTopBtn,
            backtop,
            openHelpWin,
            closeHelpWin,
            addNewsLetter,
            acceptPrivacy,
            handleClosePrivacy,
            handleCloseSubscribe
        }
    }

});

import { reactive, watchEffect } from "vue";
import common from "@/components/common";

var rules = {
    validForm: {},
    verifyASCII : function(value){
        var ASCIIReg = /[^\x00-\xff]+/g;
        if(value != "" && ASCIIReg.test(value)){
            if(this.validForm["countryId"] != "53"){
                return "myaccount.addressBook.enterEnglish".translate();
            }
        }
        return true;
    },
    loginPassword: (value):any => {
        if (!value || !value.length) {
            return 'login.password.empty'.translate();
        }
        return true;
    },
    email: value => {
        if (!value || !value.length) {
            return 'login.email.empty'.translate();
        }
        if (!/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
            return 'login.email.notexists'.translate();
        }
        return true;
    },
    validateCode: value => {
        if (!value || !value.length) {
            return 'login.validatecode.empty'.translate();
        }
        return true;
    },
    password: (value) => {
        // The field is empty so it should pass
        let re = rules.loginPassword(value);
        if (re!=true) {
            return re;
        }
        if (value.length < 6) {
            return 'register.password.type'.translate()
        }
        return true;
    },

    repeatPassword: function (value)  {
        if (!value || !value.length) {
            return 'login.password.empty'.translate();
        }
        if (value ==this.validForm["password"]) {
            return true;
        }
        return 'register.resetpassword'.translate();
    },

    firstName: value => {
        if (!value || !value.length) {
            return 'register.firstname.empty'.translate();
        }
        return true;
    },

    lastName: value => {
        if (!value || !value.length) {
            return 'register.lastname.empty'.translate();
        }
        return true;
    },

    oldPwd: value => {
        if (!value || !value.length) {
            return 'register.oldpassword.empty'.translate();
        }
        return true;
    },
    companyName:function (value){
        if (value && value.length>35) {
            return 'myaccount.addressBook.companyNameMsg'.translate();
        }
        if(this.validForm["countryId"]==53){
            return true;
        }
        return this.verifyASCII(value);
    },
    addressFirstName:function (value){
        if (!value || !value.length) {
            return 'register.firstname.empty'.translate();
        }
        if( (this.validForm['firstName']+this.validForm['lastName']).length>34){
            return "myaccount.addressBook.fullNameMsg".translate();
        }
        return this.verifyASCII(value);
    },
    addressLastName:function (value){
        if (!value || !value.length) {
            return 'register.lastname.empty'.translate();
        }
        if( (this.validForm['firstName']+this.validForm['lastName']).length>34){
            return "myaccount.addressBook.fullNameMsg".translate();
        }
        return this.verifyASCII(value);
    },
    phone:function(value){
        if (!value || !value.length || value.length>30 ||  !(/^\d+$/.test(value))) {
            return "myaccount.addressBook.phoneMsg".translate();
        }
        return true;
    },
    street1:function(value){
        if (!value || !value.length || value.length>100) {
            return "myaccount.addressBook.streetMsg".translate();
        }
        return this.verifyASCII(value);
    },
    street2:function(value){
        if (!value || !value.length){
            return true;
        }
        if ( value.length>100) {
            return "myaccount.addressBook.streetMsg".translate();
        }
        return this.verifyASCII(value);
    },
    countryId:function(value){
        if (value==null || value==0 ) {
            return "myaccount.addressBook.countryMsg".translate();
        }
        return true;
    },
    city31:function (value){
        var cityReg = /^((Las Palmas)|(las palmas de gran canaria)|(puerto de la cruz)|(TEGUISE)|(san bartolome de tirajana)|(Lanzarote)|(SANTA CRUZ DE TENERIFE TENERIFE)|(Vega de San Mateo)|(Los Cristianos)|(Cuesta de Arguijon,La))$/i;
        if(this.validForm['countryId'] == "31" &&  cityReg.test(value) ){
            this.validForm['countryId']=261;
        }
    },
    zip31:function (value){
        var zipReg = /^((35)|(38))/g;
        if(this.validForm['countryId'] == "31" &&  zipReg.test(value) ){
            this.validForm['countryId']=261;
        }
    },
    city:function(value){
        if (!value || !value.length || value.length>30) {
            return "myaccount.addressBook.cityMsg".translate();
        }
        let NumReg = /\d+/g;
        if(NumReg.test(value)){
            return "myaccount.addressBook.cityFormatMsg".translate();
        }
        if(this.validForm['countryId'] == "103") {
            if(value.toLowerCase()=='pr' || value.toLowerCase()=='pur' || value.toLowerCase().indexOf("puerto rico")!=-1 || value.toLowerCase().indexOf("the commonwealth of puerto rico")!=-1){
                this.validForm['countryId']=83;
            }
        }
        if(this.validForm['countryId'] == "34" || this.validForm['countryId'] == "201") {
            if(value.toLowerCase().indexOf("reunion")!=-1){
                this.validForm['countryId']=250;
            }
        }
        this.city31(value);
        return this.verifyASCII(value);
    },
    state:function(value){
        if (!value || !value.length || value.length>100) {
            return "myaccount.addressBook.stateMsg".translate();
        }
        if (value.toLowerCase() == "puerto rico" || value.toLowerCase() == "p.r." || value.toLowerCase() == "pr") {
            this.validForm['countryId']=83;
        }
        if (value.toLowerCase().indexOf("reunion")!=-1) {
            this.validForm['countryId']=250;
        }
        this.city31(value);
        return this.verifyASCII(value);
    },
    // 邮编校验
    zip: function (value) {
        let r = /\s+/g;
        let s = /-+/g;
        value = value.replace(r, " ").replace(s, "-");
        this.validForm['zip'] = value;

        let zipRule = this.validForm["countryZipRule"]
            .filter(r => r.countryId == this.validForm['countryId'])
            .shift();

        if (zipRule != null) {
            var regex = zipRule.regex!.replaceAll("\\\\", "\\");
            var newR = new RegExp(regex.trim());
            if (!newR.test(value)) {
                return zipRule.message;
            }
        }

        if (!value || !value.length || value.length > 10) {
            return "myaccount.addressBook.zipMsg".translate();
        }

        var regZip = /^[a-zA-Z0-9_\s\-]+$/g;
        if (!regZip.test(value)) {
            return "myaccount.addressBook.zipFormatMsg".translate();
        }

        this.zip31(value);

        return true;
    },
    fax:function(value){
        if(this.validForm["acceptMessage"] && (!value || !value.length)){
            return "myaccount.addressBook.faxMsg".translate();
        }
        if (value.length>30) {
            return "myaccount.addressBook.faxMsg".translate();
        }
        return true;
    }
};

export default function useValid(form) {
    // 将 form 变为响应式
    const reactiveForm = reactive(form);
    let isInitialized = false; // 标志是否初始化完成
    // 合并规则
    var validRules = { ...rules };
    validRules.validForm = reactiveForm;

    // 创建响应式错误对象
    var errors = reactive({
        firstErrorMsg: "",
        clear: function () {
            this.firstErrorMsg = "";
            for (const [key, val] of Object.entries(this)) {
                if (key != "clear") {
                    this[key] = "";
                }
            }
        },
    });

    // 验证单个字段
    var validField = (fieldName) => {
        if (validRules[fieldName]) {
            if (reactiveForm[fieldName] != undefined && typeof reactiveForm[fieldName] == "string") {
                reactiveForm[fieldName] = reactiveForm[fieldName].trim();
            }

            var re = validRules[fieldName](reactiveForm[fieldName]);
            if (re != true) {
                errors[fieldName] = isInitialized ? re : undefined; // 初始化完成后才设置错误信息
            } else {
                errors[fieldName] = undefined;
            }
        }
        return errors[fieldName] == undefined;
    };

    // 验证整个表单
    var valid = (exclude = [], showAlert = false) => {
        isInitialized = true; // 标志初始化完成
        errors.firstErrorMsg = "";
        let firstError = "";

        for (const [key, val] of Object.entries(reactiveForm)) {
            if (reactiveForm[key] != undefined && !validField(key)) {
                if (exclude.indexOf(errors[key]) < 0 && firstError === "") {
                    firstError = errors[key];
                }
            }
        }

        if (firstError !== "" && showAlert) {
            common
                .confirm({ title: "Please Note", text: firstError, confirmText: "OK", cancelText: "" })
                .then(async () => {})
                .catch((e) => {});
        }

        return firstError === "";
    };

    // 监听 countryId 的变化，触发相关字段校验
    watchEffect(() => {
        if (reactiveForm["countryId"]) {
            validField("zip");
        }
    });

    return { errors, validField, valid, validRules, reactiveForm };
}

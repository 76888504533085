import createApp from './main'
import {interceptors} from "./http"
import {configUseContext} from './Ph'
import createStore from '@/store';
import {defDirective} from "@/Directive";
import {createPinia} from "pinia";

  const pinia = createPinia();
  const {app, router} = createApp();

  app.use(pinia);

  defDirective(app,pinia);

  let clientData = JSON.parse(window.__client_data__.replace(/\$\!/g, "'"));

  app.provide("headInfo",clientData['headInfo']);

  configUseContext(() => {
    return {clientData: clientData, isClient: true};
  });


  interceptors.before = function (method: string, url: string, params: any, body: any, headers: any) {
    if (clientData[url]) {
      return <any>{data: clientData[url]};
    }
    return {method, url, params, body, headers};
  };

  router.isReady().then(() => {
    if (window.__INITIAL_STATE__) {
      pinia.state.value = JSON.parse(window.__INITIAL_STATE__)
    }
    app.mount('#app')
  })

import {reactive, toRefs, onMounted, defineComponent, inject, computed} from 'vue';
import {headerController, PageLocationType} from "@/service/HeaderController";

import HeaderDto from "@/service/model/header/HeaderDto";
import {loginController} from "@/service/LoginController";
import CurrencyDto from "@/service/model/header/CurrencyDto";
import useUserStore from '@/store/user'
import Cookies from 'js-cookie';
import HeaderCustomerDto from "@/service/model/header/HeaderCustomerDto";
export default defineComponent({
    props: {
        isShowLeftMenu: {
            type: Boolean,
            default: false
        }
    },
    emits:['handleCloseMenu'],
    setup(props,context) {
        const store = useUserStore()
        // 定义变量
        const state = reactive({
            isShowAllCate: <boolean> false,
            curList: <CurrencyDto[]> [],
            defCurrency:<CurrencyDto>{},
            isShowCurrencyWin: <boolean> false,
            winStyle: <any> {},
            headInfo: <HeaderDto>{},
            headCustomerInfo: <HeaderCustomerDto> {},
            isSignIn: <boolean> false,
            headerShowStockProduct:false
        });

        let headInfo = inject('headInfo');
        onMounted(async ()=>{
            // 币种下拉列表
            state.curList = <CurrencyDto[]>await headerController.getAllCurrencies();

            state.headInfo = <HeaderDto> headInfo;

            state.winStyle = {'height':  window.outerHeight + 'px'}
            // 默认选中币种
            state.defCurrency = state.headInfo.currencyDto;
            state.isSignIn = state.headInfo.customerId != null;
            // 库存开关按钮判断
            let pStock =  Cookies.get("pStock")
            if(pStock == null){
                state.headerShowStockProduct = false;
            }else{
                state.headerShowStockProduct = pStock =="1"? true: false;
            }
        })

        // 关闭弹窗
        const closeLeftMenuBox = () =>{
            context.emit('handleCloseMenu');
            document.body.style.height =  'auto';
            document.body.style.overflow = "auto";
        }
        // 显示隐藏所有分类
        const showAllCategories = () => {
            state.isShowAllCate = ! state.isShowAllCate
            state.winStyle = {'height':  window.outerHeight + 'px'}
        }

        // 显示手机币种弹窗
        const showPhoneCurrency = ()=> {
            state.isShowCurrencyWin = true;
        }

        // 关闭手机币种弹窗
        const hidePhoneCurrency = () => {
            state.isShowCurrencyWin = false;
        }

        // 修改币种
        const phoneChangeCurrency = async(code) =>{
            await headerController.changeCurrency(code);
            let url = window.location.href;
            if(url.indexOf("?currency=")!= -1){
                url = url.substring(0,url.indexOf("?"));
                window.location.href = url;
            }else if(url.indexOf("&currency=")!= -1){
                url = url.replace(/&currency=[A-Z]{3}/g,"");
                window.location.href = url;
            }else{
                window.location.reload();
            }
        }

        // 登出
        const signOut = async() => {
            await loginController.signOut();
            let url = window.location.href,
                urlArr = ['/shoppingcart','/Checkout','/Order','/MyAccount','/WishList','/TicketCenter','/uploadvideo','/uploadimage','/Register-successfully'],
                flag = false;
            urlArr.forEach((item, index)=>{
                if((url.toLowerCase()).indexOf(item.toLowerCase()) > 0) {
                    flag = true;
                }
            });
            if(flag){
                location.href= '/';
            } else {
                window.location.reload();
            }
        }

        // 库存按钮切换
        const showStockProduct = async (showStockProduct) => {
            await headerController.showStockProduct(showStockProduct);
            window.location.reload();
        }

        return{
            ...toRefs(state),
            closeLeftMenuBox,
            showAllCategories,
            showPhoneCurrency,
            hidePhoneCurrency,
            phoneChangeCurrency,
            signOut,
            showStockProduct,
        }
    }
})
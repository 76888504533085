<template>
  <div class="searchImgOverlay" v-show="isShowCamBox">
    <div class="downImgBox">
      <div class="searchCamBox">
        <p class="title">Search by Image<span class="closeSearchImg" @click="closeCamBox"></span></p>
        <div class="imgText">
          <p><b>·</b>Search kbeads with an image instead of text. Try dragging an image here.</p>
        </div>
        <div class="box clearfix">
          <div class="navItems">
            <div class="top fixnav navnormal">
              <div class="nav">
                <span :class="{'current': searchType == 'img'}" @click="handleChangeCamWay('img')">Upload an image</span>
                <span :class="{'current': searchType == 'url'}" @click="handleChangeCamWay('url')">Paste image URL</span>
              </div>
            </div>
          </div>
          <div class="content">
            <ul>
              <li>
                <dl class="subBox">
                  <dd v-show="searchType == 'img'">
                    <input type="file" name="file" accept="images/*" ref="uploadImg" @change="uploadSearchImg">
                  </dd>
                  <dd v-show="searchType == 'url'">
                    <input type="text" class="searchBy" v-model.trim="imgUrl">
                    <input type="button" class="searchImage" value="Search by Image" @click="uploadSearchImgByUrl">
                  </dd>
                  <dd style="color: #cc0000">{{errMsg}}</dd>
                  <dd v-if="!isUpload">1. The image must be in one of the following formats: .jpg, .png, .jpeg, .bmp, .gif, .webp, .tiff, .ppm</dd>
                  <dd v-if="!isUpload">2. The size of the image should be less than 5M!</dd>
                  <dd v-if="!isUpload">3. The image length and width must be greater than or equal 200 pixels and smaller than or equal to 1024pixes.</dd>
                </dl>
              </li>
            </ul>
          </div>
          <p class="seaImgLoading" v-if="isUpload">
            <span>Uploading File</span>
            <img src="https://www.pandahall.com/buyer/images_new/customershow/load.gif">
          </p>

        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./SeaPhoto.ts"></script>

<style src="./seaPhoto.less" scoped lang="less"></style>
import {onMounted, onServerPrefetch, reactive, ref, toRefs, watch} from 'vue';
import Login from "@/views/login/login/Login.vue";
import Modal from "@/components/common/modal/Modal.vue";
import Register from "@/views/login/register/Register.vue";
import InitSignInDto from "@/service/model/login/InitSignInDto";
import {loginController} from "@/service/LoginController";
import ModalData from "@/service/model/ModalData";
export default {
    name: "PopLogin",
    props: {
        isReg:{
            type:Boolean,
            default:true
        },
        submitCallback: {
            type: Function // 确认事件
        },
        cancelCallback: {
            type: Function // 取消事件
        }
    },
    components:{Modal, Login, Register},
    setup(props,content) {

        const data = reactive({
            modal:new ModalData(true, "Sign In", false, false),
            isReg: props.isReg,
            loading: false
         });

        let regTitle= "";
        onMounted(async () => {
            data.loading = true
            let params:any = '';
            // 是否展示验证码
            let initSignInDto: InitSignInDto = await loginController.initSignIn(params);
            let registerPrompt="register.money".translate(initSignInDto.regCouponPrompt["5"]);
            regTitle= `Register 
                  <span class="regTips hidden-xs">
                    <b >${registerPrompt}</b>
                   </span>`;
            if(data.isReg){
                data.modal.title = regTitle;
            }else{
                data.modal.title="Sign In";
            }
            data.loading = false
        })

        watch(()=>data.isReg,()=>{
            if(data.isReg){
                data.modal.title=regTitle;
            }else{
                data.modal.title="Sign In";
            }
        });

        const toReg = () => {
            data.isReg = true
        }

        const toSign = () => {
            data.isReg = false
        }

        const confirmModal = (sign:boolean) => {
            // 关闭模态框
            if (!sign) {
                props.cancelCallback()
                return;
            }
        }
        return {
            ...toRefs(data),
            confirmModal,
            toReg,
            toSign
        }
    }
}
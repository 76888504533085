import {GetMapping, PostMapping, RequestBody, RequestMapping2Class, RequestParam} from '../http'
import LoginFormDto from "./model/login/LoginFormDto";
import RegisterFormDto from "./model/login/RegisterFormDto";
import InitSignInDto from "@/service/model/login/InitSignInDto";

@RequestMapping2Class("/login")
class LoginController{
    @GetMapping("/initSignIn")
    initSignIn(@RequestParam("access") access: string):Promise<InitSignInDto>{ throw 1; }

    @PostMapping("/login")
    login(@RequestBody loginForm: LoginFormDto) { throw 1; }

    @PostMapping("/register")
    register(@RequestBody registerForm: RegisterFormDto): Promise<any> { throw 1; }

    @GetMapping("/signOut")
    signOut(){throw 1;}

    @GetMapping("/isLogin")
    isLogin(): Promise<boolean>{throw 1;}
}
const loginController = new LoginController();
export { loginController };


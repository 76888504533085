<!-- ClientOnly.vue -->
<template>
  <template v-if="isClient">
    <slot></slot>
  </template>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import {useContext} from "@/Ph";

export default defineComponent({
  setup() {
    const context = useContext();
    const isClient = ref(false);
    // 确保组件在客户端时更新 isClient 的值
    onMounted(() => {
      isClient.value = context.isClient;
    });

    return {
      isClient,
    };
  },
});
</script>
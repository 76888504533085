<template>
  <div class="breadCrumbs clearfix">
    <i class="firstChild home">
      <a href="/">Home</a>
      <span class="hidden-sm hidden-md">&nbsp;&gt;&nbsp;</span>
    </i>
    <i :class="{'on': secondCrumb == null }">
      <a :href="firstUrl? firstUrl: 'javascript:;'">{{firstCrumb}}
        <span class="borderBg"></span></a>
        <span class="hidden-sm hidden-md" v-if="secondCrumb != null">&nbsp;&gt;&nbsp;</span>
    </i>
    <i :class="{'on': secondCrumb != null && thirdlyCrumb == null}" v-if="secondCrumb != null">
      <a :href="secondUrl? secondUrl: 'javascript:;'">{{secondCrumb}}<span class="borderBg"></span></a>
      <a><span class="borderBg"></span></a>
    </i>
    <i :class="{'on': thirdlyCrumb != null }" v-if="thirdlyCrumb != null">
      <a>{{thirdlyCrumb}}<span class="borderBg"></span></a>
      <a><span class="borderBg"></span></a>
    </i>
  </div>
</template>
<script src="./Crumb.ts"></script>
<style src="./crumb.less" scoped lang="less"></style>
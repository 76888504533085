import Decimal from "decimal.js-light";
import CurrencyDto from "../service/model/header/CurrencyDto";

export const PRICE_FORMAT: string = "#,##0.00";
export const NUMBER_FORMAT: string = "#,##0";

export let defaultCurrency = {};

/**
 * 返回指定货币格式的金额,需要处理特殊币种,比如日元需要取整
 * 头部统计购物车金额使用
 * @param currency
 * @param money
 * @return
 */
export function getMoneyOfCurrency(currency: CurrencyDto, money: number): string {
    return getMoneyOfCurrencyRoundToInt(currency, getMoneyOfCurrencyNoSingle(currency, money));
}

/**
 * 当前币种系数 乘以 金额 乘以 人民币系数 再除以 0.965
 * @param cnyCurrency
 * @param currentCurrency
 * @param money
 * @return
 */
export function getBankCNYMoneyOfCurrency(cnyCurrency: CurrencyDto,currentCurrency: CurrencyDto, money: number): string {
    if (!cnyCurrency || !cnyCurrency.code || cnyCurrency.code.toUpperCase() !== 'CNY')
        return '';
    if (money == null)
        money = 0;
    let currencyRate = (currentCurrency && cnyCurrency.rate) ? new Decimal(currentCurrency.rate):new Decimal(1);
    let cnyRate = cnyCurrency.rate?new Decimal(cnyCurrency.rate):new Decimal(1);
    let currentAmount = currencyRate.mul(money).todp(2, Decimal.ROUND_HALF_UP);
    let amount = cnyRate.mul(currentAmount).div(new Decimal("0.965")).todp(2, Decimal.ROUND_HALF_UP).toNumber();
    return cnyCurrency.sign + amount.format(PRICE_FORMAT);
}

/**
 * 用户输入金额 乘以 人民币系数 再除以 0.965
 * 需要除以 0.965
 * @param cnyCurrency
 * @param money
 * @return
 */
export function getBankInfoCNYMoneyOfCurrency(cnyCurrency: CurrencyDto, money: number): string {
    if (!cnyCurrency || !cnyCurrency.code || cnyCurrency.code.toUpperCase() !== 'CNY')
        return '';
    if (money == null)
        money = 0;
    let rate = cnyCurrency.rate?new Decimal(cnyCurrency.rate):new Decimal(1);
    let amount = rate.mul(money).div(new Decimal("0.965")).todp(2, Decimal.ROUND_HALF_UP).toNumber();
    return cnyCurrency.sign + amount.format(PRICE_FORMAT);
}


/**
 * 返回人民币货币格式的金额
 * 需要除以 0.965
 * @param cnyCurrency
 * @param money
 * @return
 */
export function getCNYMoneyOfCurrencyRoundToInt(currency: CurrencyDto, money: number): string {
    if (!currency || !currency.code || currency.code.toUpperCase() !== 'CNY')
        return '';
    if (money == null)
        money = 0;
    let rate = currency.rate?new Decimal(currency.rate):new Decimal(1);
    let amount = rate.mul(money).div(new Decimal("0.965")).todp(2, Decimal.ROUND_HALF_UP).toNumber();
    return currency.sign + amount.format(PRICE_FORMAT);
}

export function getMoneyOfCurrencyIncludeVat(currency: CurrencyDto, money: number,vatRate:number): string {
    let point = currency.code == "JPY"|| currency.code == "HUF"?0:2;
    money = new Decimal(getMoneyOfCurrencyNoSingle(currency, money)).mul(1+vatRate).todp(point, Decimal.ROUND_HALF_UP).toNumber();
    return getMoneyOfCurrencyRoundToInt(currency,money);
}

export function getVatOfSimpleCurrency(currency: CurrencyDto, money: number,vatRate:number): string {
    let point = currency.code == "JPY"|| currency.code == "HUF"?0:2;
    money = new Decimal(getMoneyOfCurrencyNoSingle(currency, money)).mul(1+vatRate).todp(point, Decimal.ROUND_HALF_UP).toNumber();
    return getMoneyOfSimpleCurrency(currency,money);
}

function isSpecialCurrencyNeedRoundUpToInt(currencyCode:string):boolean{
    return currencyCode == "JPY"|| currencyCode == "HUF";
}

/**
* 返回指定货币简写格式的金额
*
* @param currencyCode
*            货币简码，比如USD代表美元
* @param money
*            金额
* @return
*/
export  function getMoneyOfSimpleCurrency(currency: CurrencyDto, money: number) :string{
    let currencySign = currency.simpleSign;
    money = money == null ? 0 : money;
    if (isSpecialCurrencyNeedRoundUpToInt(currency.code)) {
        money = getLegalMoneyOfCurrency(currency.code, money);
        return currencySign + money.format(NUMBER_FORMAT);
    }
    if ("EUR".equalsIgnoreCase(currency.code)){
        return money.format(PRICE_FORMAT).replace(',',' ').replace('.',',') + currencySign;
    }
    return currencySign + money.format(PRICE_FORMAT);
}




/**
 * 返回指定汇率的金额
 *
 * @param currency
 * @param money
 * @return
 */
export function getMoneyOfCurrencyNoSingle(currency: CurrencyDto, money: number): number {
    if (currency == null || money == null) {
        return 0;
    } else {
        // 由于PH目前是算好美元再切换币种的所有这里要处理一下
        let rate = currency.rate?new Decimal(currency.rate):new Decimal(1);
        let point = isSpecialCurrencyNeedRoundUpToInt(currency.code)?0:2;
        return rate.mul(new Decimal(money).todp(2, Decimal.ROUND_HALF_UP)).todp(point, Decimal.ROUND_HALF_UP).toNumber();
    }
}

/**
 * 获取指定币种2位小数的合法金额<br/> 如果是日币等特殊币种需要四舍五入取整
 *
 * @param currencyCode
 * @param money
 * @return
 */
function getLegalMoneyOfCurrency(currencyCode: string, money: number): number {
    if (isSpecialCurrencyNeedRoundUpToInt(currencyCode)) {
        return new Decimal(money).todp(0, Decimal.ROUND_HALF_UP).toNumber();
    }
    return new Decimal(money).todp(2, Decimal.ROUND_HALF_UP).toNumber();
}

/**
 * 返回指定货币格式的金额,需要处理特殊币种,比如日元需要取整
 *
 * @param currencyCode
 * @param money
 * @return
 */
export function getMoneyOfCurrencyRoundToInt(currency: CurrencyDto, money: number): string {
    let currencySign = currency.sign;
    let code = currency.code?currency.code:'USD';
    money = money == null ? 0 : money;
    if (isSpecialCurrencyNeedRoundUpToInt(code)) {
        money = getLegalMoneyOfCurrency(code, money);
        return currencySign + money.format(NUMBER_FORMAT);
    }
    /**
     * 欧元金额显示：欧元符号在后，小数点间隔符改用逗号，千位分隔符改用空格
     * €1,110.90 → 1 110,90€
     */
    if ("EUR".equalsIgnoreCase(code))
        return money.format(PRICE_FORMAT).replace(',', ' ').replace('.', ',') + currencySign;
    return currencySign + money.format(PRICE_FORMAT);
}

/**
 * 转汇率，再格式化金额
 */
Number.prototype.money = function(currency?:any):string{
        let currencyDto:CurrencyDto=currency;
        if(!currency){
            // @ts-ignore
            currencyDto = <CurrencyDto>(defaultCurrency['key']);
        }
        if(!currencyDto){
            currencyDto={
                "id": 1,
                "code": "USD",
                "sign": "US $",
                "fullSign": "$USD",
                "displayName": "US Dollar",
                "secFlag": undefined,
                "rate": 1,
                "simpleSign": "$"
            }
        }
    return getMoneyOfCurrency(currencyDto,<number>this);
}

/**
 * 不转汇率，直接格式化金额
 */
Number.prototype.moneyWithoutRate = function():string{
    // @ts-ignore
    let currencyDto:CurrencyDto=<CurrencyDto>(defaultCurrency['key']);
    if(!currencyDto){
        currencyDto={
            "id": 1,
            "code": "USD",
            "sign": "US $",
            "fullSign": "$USD",
            "displayName": "US Dollar",
            "secFlag": undefined,
            "rate": 1,
            "simpleSign": "$"
        }
    }
    return getMoneyOfCurrencyRoundToInt(currencyDto,<number>this);
}
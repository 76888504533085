import {addToCart, batchAddToCart} from "@/components/common/addToCart";
import {addToWishList} from "@/components/common/addToWishList";
import buyNow from "@/components/common/buyNow";
import confirm from "@/components/common/confirm";
import popLogin from "@/components/common/popLogin";

 const common =  {
    addToCart,
    addToWishList,
    batchAddToCart,
    buyNow,
    confirm,
    popLogin
}

export default common;
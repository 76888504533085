import {createApp} from "vue"
import BuyNow from '@/components/common/buyNow/BuyNow.vue'


export default function buyNow(pid:number,qty:number,dom, store):Promise<any>{
    let popDom = document.getElementsByClassName('pop-container')
    if(popDom.length > 0) {
        popDom.item(0).remove();
    }
    // 创建一个dom节点
    const div = document.createElement('div')
    div.setAttribute('class', 'pop-container')
    // 挂载到body就可以在最顶层直接控制显示隐藏
    document.body.appendChild(div)
    return new Promise((resolve: any, reject)=>{

        const close=()=>{
            div.remove();
        };
        // 封装组件属性方法
        const submitCallback = (returnObj) => {
            //调用完毕后应该清空节点
            resolve(returnObj);
            setTimeout(close, 3000)
        }

        // 封装组件属性方法
        const cancelCallback = () => {
            //清空节点
            div.remove()
            reject()
        }

        // 在此处才创建节点并挂载属性
        const buyNowApp = createApp(BuyNow, {
            submitCallback,
            cancelCallback,
            pid,
            qty,
            dom,
            div
        })

        // 渲染节点到指定dom
        buyNowApp.use(store).mount(div)
    });
}
import {reactive} from 'vue'
import ModalData from '@/service/model/ModalData';

export default {
    name: 'modal',
    props: {
       'modal': {
           type: ModalData,
           required: true
       }
    },
    setup(props: any, content: any) {
        const modal = reactive(props.modal);
        const confirmModal = (sign:boolean) => {
            // 子组件向父组件传值
            content.emit('confirmModal', sign);
        }
        return {
            modal,
            confirmModal,
        }
    },
}
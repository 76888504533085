<template>
    <div class="headBanner" :style="bannerStyle" v-if="isShowProBanner">
      <div class="promTime">
        <a :title=promotionBannerDto.title :href=promotionBannerDto.url>
          <span class="hidden-md hidden-xs">{{promotionBannerDto.title}}</span>
        </a>
        <div class="promNum">
          <ul class="clearfix">
            <li class="day">{{day}}<span class="hidden-md" style="letter-spacing: normal">Days</span></li>
            <li class="top3 hour">{{hour}}<span class="hidden-md">:</span></li>
            <li class="top3 min">{{minute}}<span class="hidden-md">:</span></li>
            <li class="top3 sec">{{second}}</li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script lang="ts" src="./Promotion.ts"></script>

<style scoped src="./promotion.less" lang="less"></style>
<template>
    <div class="search-box01 hidden-sm hidden-md" v-if="isShowPhoneSearch">
      <nav class="top-nav cls">
        <div class="close-search">
          <em class="icon-times" @click="closeSearchBox"></em>
        </div>
        <div class="top-search cls">
          <form action="/ProductSearch" class="">
            <div class="search-form">
              <div class="search-area">
                <input type="search" name="keyword" placeholder="Enter your search key"
                       maxlength="100" class="search-input" autocomplete="off"
                       @input="searchkeywordTip(searchVal)"
                       v-model.trim="searchVal">
                <span class="clear-btn" @click="clearWords"></span>
              </div>
            </div>
          </form>
        </div>
        <div class="up-img-search">
          <a class="searchImg"></a>
          <form enctype="multipart/form-data" style="display: inline-block">
            <input type="file" class="phoneCam" name="file" accept="image/*" ref="uploadImg" @change="searchProByCamPhone">
          </form>
        </div>
      </nav>

      <ul class="suggestions">
        <li v-for="(item, index) in keyWorkList" :key="index">
          <a href="javascript:;" @click="search(item)" v-html="item"></a>
        </li>
      </ul>
    </div>
</template>

<script lang="ts" src="./Search.ts"></script>

<style scoped src="./search.less" lang="less"></style>
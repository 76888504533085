import {GetMapping,PostMapping, RequestMapping2Class, RequestPart, RequestParam} from '../http'
import CurrencyDto from "./model/header/CurrencyDto";
import  CommonDto from "./model/header/CommonDto";
import {ShowCategoryVo} from "./model/header/ShowCategoryVo";
import HeaderDto from "./model/header/HeaderDto";
// 当前页面位置
export enum PageLocationType {
    OTHER= -1,INDEX,CART,MYACCOUNT
}
export enum LocationType {
    OTHER, CART, CHECKOUT, PAYMENT,PAYSUCCESS
}


@RequestMapping2Class("/header")
class HeaderController{
    @GetMapping("/getAllCurrencies")
    getAllCurrencies(): Promise<CurrencyDto[]> { throw 1; }

    @PostMapping("/showStockProduct")
    showStockProduct(@RequestParam ("showStock")showStockProduct: string):Promise<CommonDto> { throw 1; }

    @PostMapping("/changeCurrency")
    changeCurrency(@RequestParam ("currencyCode")currencyCode: string):Promise<CommonDto> { throw 1; }

    @GetMapping("/isSignIn")
    isSignIn(): Promise<boolean>  { throw 1; }

    // 图片上传相关
    @PostMapping("/uploadSearchImg")
    uploadSearchImg(@RequestPart("file") file:Blob): Promise<string> { throw 1; }

    @PostMapping("/uploadSearchImg")
    uploadSearchImgByUrl(@RequestParam ("fileFileName")fileFileName: string): Promise<string> { throw 1; }

    // 搜索Top10
    @GetMapping("/getKeyWork")
    getKeyWork():Promise<string[]>{throw 1}

    @GetMapping("/searchKeywordTip")
    searchkeywordTip(@RequestParam("keyword") keyword: string):Promise<string[]>{throw 1}

    @GetMapping("/listHeaderCategoryTree")
    listHeaderCategoryTree():Promise<ShowCategoryVo[]>{throw 1}

    @GetMapping("/getHeadInfo")
    getHeadInfo(@RequestParam("previewNotice")  previewNotice:number,
                @RequestParam("position") position:number,
                @RequestParam("isPreview")isPreview:number):Promise<HeaderDto> {throw 1}

    @GetMapping("/addNewsLetter")
    addNewsLetter(@RequestParam("email")email: string):Promise<boolean>{throw 1}

    @GetMapping("/getWebsiteCopyrightInfo")
    getWebsiteCopyrightInfo():Promise<string>{throw 1}

    @GetMapping("/isShowPolTipBox")
    isShowPolTipBox():Promise<boolean>{throw 1}

    @GetMapping("/isSubscribedNewsletter")
    isSubscribedNewsletter():Promise<string>{throw 1}

    @GetMapping("/loadCategory1Banner")
    loadCategory1Banner(@RequestParam("isPreview")isPreview: number):Promise<string>{throw 1}

    @GetMapping("/holidayAndCollections")
    holidayAndCollections(@RequestParam("preview")preview: number):Promise<string>{throw 1}
}
const headerController = new HeaderController();
export { headerController };


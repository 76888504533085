import { defineStore } from 'pinia'
import HeaderDto from "@/service/model/header/HeaderDto";

export default defineStore('user', {
  state: () => ({
    systemError: false,
    pageNotFound: false,
    lastShoppingUrl: '',
    headerDto:{
      firstName: '',
      lastName: '',
      headerCustomerDto: {},
      simulatedLogin: false,
    },
    orderStatus: 0,
    thirdlyCrumb: null,
  }),
  actions: {
    updateHeader(newValue: HeaderDto) {
      // 只提取需要的字段
      const { firstName, lastName, headerCustomerDto,simulatedLogin} = newValue;
      this.headerDto = {
        firstName,
        lastName,
        headerCustomerDto,
        simulatedLogin
      };
    },
    updateHeadCart({qty, price}) {
      this.headerDto.headerCustomerDto.cartItemCount = qty
      this.headerDto.headerCustomerDto.cartTotalAmount = price
    },
    updateHeadWishList(qty) {
      this.headerDto.headerCustomerDto.wishListItemCount = qty
    },
    updateHeadName({firstName, lastName}) {
      this.headerDto.firstName = firstName
      this.headerDto.lastName = lastName
    },
    updateLastShoppingUrl(url) {
      this.lastShoppingUrl = url;
    },
    setOrderStatus(status) {
      this.orderStatus = status;
    },
    setThirdlyCrumb(crumb) {
      this.thirdlyCrumb = crumb;
    }
  }
})

<template>
  <div class="head clearfix hidden-xs" :class="{'orderHead':  processClass != 'process'}">
    <div class="logo" :class="{'orderLogo':  processClass != 'process'}">
      <a href="/" aria-label="cobeads"></a>
    </div>
    <p class="orderProcess processCartList hidden-xs hidden-sm" :class="processClass" v-if="processClass != 'process'">
      <a href="/ShoppingCart-ShoppingCart-1" title="Shopping Cart">{{"header.shopCart".translate()}}</a>
    </p>
    <div class="searchArea clearfix" v-if="processClass == 'process'">
      <div class="nav-search">
        <form method="get" action="/ProductSearch">
          <div class="searchbar-operate-box">
            <input type="submit" class="searchButton" title="Search" alt="Search" value="search">
            <input type="hidden" name="effectSearch" value="1"/>
            <span class="searchImg hidden-sm" @click="handleSearchByCam"></span>
          </div>
          <p class="search">
            <input type="text" maxlength="200" autocomplete="off" name="keyword" class="search_input txtTips"
                   aria-label="keywords"
                   v-model="keyword"
                   @focus="showSearchPromp" @blur="hideSearchPromp" @input="searchkeywordTip()">
          </p>
        </form>
        <div class="searchPrompt" v-show="keyWorkList.length> 0 && isShowSearchPromp">
          <ul>
            <li v-for="(item, index) in keyWorkList" :key="index">
              <a href="javascript:;" @mousedown="search(item)">{{index + 1}}.{{item}}</a>
            </li>
          </ul>
        </div>
        <div class="searchPrompt searchKeyTip" v-show="searchWordResult.length> 0  && isShowSearchContent">
          <ul :class="{'less': isHideLi}">
            <li class="hideLi clearfix" @click="handleHideLi"><b class="fl">Search Suggestion</b><span class="fr">Hide</span></li>
            <li class="searchLi" v-for="(item, index) in searchWordResult" :key="index">
              <a href="javascript:;" @mousedown="search(item)" v-html="item"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="header-r">
      <a class="footprint fl hidden-sm" href="/MyAccount/myFootPrint" v-if="processClass == 'process'"><p>{{"header.myFootPrint".translate()}}</p></a>
      <div class="loginWrap fl" @mouseenter="hoverSign(true)" @mouseleave="hoverSign(false)"  :class="{'orderLogin':  processClass != 'process'}">
        <p class="title" v-if="!isSignIn">{{"header.signIn".translate()}}</p>
        <p class="name" v-else>Hi,{{headInfo.firstName + " " + headInfo.lastName}}</p>
        <span class="Bg_FFF"></span>
        <div class="signIn" v-show="isShowSignBox">
          <p class="signBtn" v-if="!isSignIn">
            <input onclick="window.location='/Customer/SignIn';" class="redBtn" value="Sign In" type="button">
          </p>
          <p class="tip" v-if="!isSignIn">{{"header.newCustomer".translate()}}?</p>
          <p class="joinBtn" v-if="!isSignIn">
            <input onclick="window.location='/Customer/SignIn?q=reg';" class="blackBtn" value="Join Free" type="button">
          </p>
          <p class="signOut" v-if="isSignIn">
            <a rel="nofollow" @click="signOut" href="javascript:;">{{"header.signOut".translate()}}</a>
          </p>
          <dl class="menuList">
            <dd>
              <a rel="nofollow" href="/MyAccount">{{"header.myAccount".translate()}}</a>
            </dd>
            <dd>
              <a rel="nofollow" href="/Order/MyOrders">{{"header.myOrders".translate()}}</a>
            </dd>
            <dd class="Currency">
              <a rel="nofollow" href="/WishList/MyWishList">{{"header.wishList".translate()}}</a>
            </dd>
            <dd>
              <a rel="nofollow" href="/TicketCenter/AllTickets">{{"header.ticketCenter".translate()}}
                <template v-if="headCustomerInfo && headCustomerInfo.unReadTicketQty">
                  (<span style="color: #87be45;">{{headCustomerInfo.unReadTicketQty}}</span>)
                </template>
              </a>
            </dd>
            <template v-if="isSignIn">
              <dd v-if="headCustomerInfo.onlyForYouItems > 0">
                <a rel="nofollow" href="/MyAccount/OnlyForYouProducts">{{"header.onlyForYou".translate()}}</a>
              </dd>
              <dd >
                <a rel="nofollow" href="/Coupon/Coupon">{{"header.myCoupons".translate()}}
                  <span v-if="headCustomerInfo.has7DaysValidate">(<span style="color:#e1781d;">{{"header.expireSoon".translate()}} !</span>)</span>
                </a>
              </dd>
              <dd >
                <a rel="nofollow" href="/Cash/CashAccount">{{"header.cashAccount".translate()}}</a>
              </dd>
              <dd >
                <a rel="nofollow" href="/Points">{{"header.points".translate()}}</a>
              </dd>
            </template>
            <dd>
              <a rel="nofollow" href="/HelpCenter/HelpAsk">{{"header.contactUs".translate()}}</a>
            </dd>
          </dl>
        </div>
      </div>
      <a class="wishList fl" href="/WishList/MyWishList" v-if="processClass == 'process'">
        <p id="spanWishListCount">{{"header.wishList".translate()}}<span>(<span class="red">{{headCustomerInfo.wishListItemCount <= 99 ? headCustomerInfo.wishListItemCount: '99+'}}</span>)</span></p>
      </a>
      <a rel="nofollow" class="cartItem fl jsSpnCartTotal" href="/ShoppingCart-ShoppingCart-1" :title="'Total: '+ headCustomerInfo.cartTotalAmount?.money()" v-if="processClass == 'process'">
        <p class="cart">{{"header.myCart".translate()}}(<span class="red jsSpnCartCount">{{headCustomerInfo.cartItemCount <= 99 ? headCustomerInfo.cartItemCount: '99+'}}</span>)</p>
      </a>
    </div>
  </div>
  <client-only>
    <!-- 弹窗显示隐藏 -->
    <SeaPhoto :is-show-cam-box="isShowCamBox" @handleClose="handleClose"/>
  </client-only>
</template>

<script lang="ts" src="./Search.ts"></script>

<style src="./search.less" scoped lang="less"></style>
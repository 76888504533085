import {useContext} from "@/Ph";
import {ref} from "vue";

export enum PhScreen {
    PC,PC_SMALL,PAD,PHONE,PHONE_SMALL
}


export function  getScreen():PhScreen{
    const context = useContext();
    let isClient = context.isClient;
    // 服务端取不到window 对象, 判断浏览器的user-agent
    if (!isClient || typeof window === 'undefined') {
        const userAgent = context.request?.headers['user-agent'] || '';
        if (/Mobile|Android|iP(hone|od|ad)/.test(userAgent)) {
            return PhScreen.PHONE;
        } else if (/Tablet|iPad/.test(userAgent)) {
            return PhScreen.PAD;
        } else {
            return PhScreen.PC;
        }
    }
    // 客户端 判断
    if(window.matchMedia('(min-width:1263px)').matches){
        return PhScreen.PC;
    }else if(window.matchMedia('(min-width:992px)').matches){
        return PhScreen.PC_SMALL;
    }else if(window.matchMedia('(min-width:768px)').matches){
        return PhScreen.PAD;
    }else if(window.matchMedia('(min-width:560px)').matches){
        return PhScreen.PHONE;
    }else if(window.matchMedia('(max-width:560px)').matches){
        return PhScreen.PHONE_SMALL;
    }
    return PhScreen.PC;
}

const en={
    "header.contactUs":"Contact Us",
    "header.shippingFee":"Shipping Fee",
    "header.orderTracking":"Order Tracking",
    "header.myAccount":"My Account",
    // footer
    "header.help":"Help",
    "header.cart":"Cart",
    "header.shop":"SHOP",
    "header.subscribe":"Subscribe to our mailing list",
    "header.jewelryBeads":"Jewelry Beads",
    "header.jewelryFindings":"Jewelry Findings",
    "header.beadingSupplies":"Beading Supplies",
    "header.stringingMaterials":"Stringing Materials",
    "header.jewelryWatches":"Jewelry & Watches",
    "header.hairFindings":"Hair Accessories & Findings",
    "header.sewingKnitting":"Sewing & Knitting",
    "header.homeGarden":"Office & Home & Garden",
    "header.information":"INFORMATION",
    "header.aboutUs":"About Us",
    "header.discountPolicy":"Discount Policy",
    "header.sitemap":"Sitemap",
    "header.paymentInfo":"Payment Info",
    "header.customerService":"CUSTOMER SERVICE",
    "header.shippingDelivery":"Shipping & Delivery",
    "header.privacyPolicy":"Privacy Policy",
    "header.returnPolicy":"Return Policy",
    "header.helpFaq":"Help & FAQ",
    "header.customsDuties":"Customs & Duties",
    "header.termsConditions":"Terms & Conditions",
    "header.latestLbeads":"LATEST FROM LBEADS",
    "header.latestCobeads":"LATEST FROM COBEADS",
    "header.joinUsSlogan":"Join us for news,updates,special deals,discounts and everything about beads.",
    "header.stayConnected":"STAY CONNECTED",
    "header.myFootPrint":"My Footprint",
    "header.signIn":"Sign In",
    "header.shopCart":"Shopping Cart",
    "header.newCustomer":"New Customer",
    "header.signOut":"Sign out",
    "header.cashAccount":"Cash Account",
    "header.myOrders":"My Orders",
    "header.wishList":"Wish List",
    "header.ticketCenter":"Ticket Center",
    "header.onlyForYou":"Only For You",
    "header.myCoupons":"My Coupons",
    "header.expireSoon": "Expire Soon",
    "header.myCash":"My Cash",
    "header.points":"Points",
    "header.myCart":"My Cart",
    "header.currency":"Currency",
    "footer.helpCenter":"Help Center",
    "footer.searchHelp":"Search the help pages",
    "footer.searchItems":"Does not search for items or products",
    "footer.askQuestions":"Most Frequently Asked Question",
    // 上传图片
    "header.searchByImage": "Search By Image",
    "header.searchLbeads": "Search lbeads with an image instead of text. Try dragging an image here.",
    "header.uploadImg": "Upload an image",
    "header.pasteImg": "Paste image URL",
    "header.uploadImgTip1": "The image must be in one of the following formats: .jpg, .png, .jpeg, .bmp, .gif, .webp, .tiff, .ppm",
    "header.uploadImgTip2": "The size of the image should be less than 5M!",
    "header.uploadImgTip3": "The image length and width must be greater than or equal 200 pixels and smaller than or equal to 1024pixes.",
    "header.uploadFile": "Upload File",
    // 导航
    "header.allCategories":"All Categories",
    "header.bestSellers": "Best Sellers",
    "header.newArrivals": "New Arrivals",
    "header.mixedProducts": "Mixed Products",
    "header.promotion": "Promotion",
    "header.bulkArticle": "Bulk Article",
    "header.smallBatch": "Small Batch",
    "header.smallLot": "Small Lot",
    "header.collections": "Collections",
    "header.trends": "Trends",
    "header.hot": "Weekly Hot",
    "header.clearance": "Clearance",
    "header.boutique": "Boutique",
    "header.designStudio": "Design Studio",
    "header.jewelryGallery": "Jewelry Gallery",
    "header.holidayCollection": "",
    // 手机
    "header.repackingService": "Repacking Service",
    "header.deliveryFee": "Delivery Fee",
    "header.changeCurrency": "Change Currency",
    "header.register":"Register",
    "header.newArri":"New<br/>Arrivals",
    "header.bestSeller":"Best <br>Sellers",
    "header.designStudi":"Design<br>Studio",
    "footer.privacy":"We and our partners use cookies and other technologies to enhance your experience, measure performance, and customize marketing strategies. For more details, please refer to our <a style='color:#fff;text-decoration: underline' href=\"/HelpCenter/HelpDetail?articleId=127&amp;categoryId=17 \">Privacy Policy</a>.",

    'login.email.exists':'It appears that you already have an account with us.',
    'login.email.empty': 'Please enter your e-mail address.',
    'login.email.notexists':'Please enter a correct email address.',
    'login.email.locked':'Your account has be locked.',
    'login.password.empty':'Please enter your password.',
    'login.validatecode.error': 'The characters you entered don\'t match what are shown in the image.',
    'login.validatecode.empty':'Please enter the characters as they are shown in the image.',
    'register.password.error':'The password is incorrect.',
    'register.email.exists.error':'An account already exists for this email address.',
    'register.oldpassword.empty':'Please enter your old password.',
    'register.lastname.empty': 'Please enter your last name.',
    'register.firstname.empty': 'Please enter your first name.',
    'register.password.type':"The password should consist with 6 - 20 characters (A-Z, a-z, 0-9 ，!\"#$%&'()*+,-./:;<=>?[\\]^_`{|}~ only).",
    'register.resetpassword':"It doesn't match your password. Please try again.",
    "account.password.error":"The current password is incorrect. Please reenter.",
    "register.password.forOrder":"for order over",
    "register.password.valid":"Valid for",
    "register.password.days":"Days",
    "register.password.terms":"Terms and Conditions",
    "register.password.forNewCustomers":"for New Customers",
    "register.password.conditions":"• Cobeads reserves the right to the ultimate interpretation of the above terms &amp; conditions.",
    "register.password.unavailable":"• <span style=\"color: #f94d5a;\">ONLY</span> for new customers, unavailable for repeated registration;",
    "register.password.orderCan":"• One order can be paid by only <span style=\"color: #f94d5a;\">ONE</span> coupon;",
    "register.password.eachCoupon":"• Each coupon can be used only <span style=\"color: #f94d5a;\">ONCE</span>;",
    "register.password.excluding":"• Coupon is <span style=\"color: #f94d5a;\">ONLY</span> for product cost, excluding shipping fee;",

    // 登录页
    'login.email':'Email',
    'login.password':'Password',
    'login.verificationCode':'Verification Code',
    'login.keepLogin':'keep me signed in on this computer unless I sign out',
    'login.forgetPass':'Forgotten password',
    'login.signIn':'Sign In',
    'login.title': 'cobeads.com Sign in / Register',
    'login.text':'If you already have a cobeads.com websites account, you can login with the account information.',
    'register.subscribeNewsletter':'I would like subscribe coBeads newsletter with the latest offers and news(up to 2 Emails per week)',
    "myaccount.confirmLb":"Are you sure to deactivating your account?\n" +
        "You will have no access to your cobeads.com account.",

    'register.money':"{0},<a target=\"_blank\" href=\"/HelpCenter/HelpDetail?articleId=139\" class=\"fontRed\"> click here</a> for details",
    'register.register':'Register',
    'register.selectEmail':'Please select email type',
    'register.weak':'Weak',
    'register.medium':'Medium',
    'register.strong':'Strong',
    'register.rePass':'Re-Password',
    'register.fisrtName':'First Name',
    'register.lastName':'Last Name',
    'register.saveCart':'Save products to shopping cart',
    'register.trackOrders':'Track delivery of your orders ',
    //  注册成功页面
    'registerSuccess.title':'Register Successfully',
    'registerSuccess.thankRegister':'Thanks for registering with cobeads.com',
    'registerSuccess.thankLbRegister':'Thanks for registering with lbeads.com',
    'registerSuccess.memberId': 'Your Member ID:',
    'registerSuccess.offerCoupon':'{0}.Have a nice shopping at cobeads!',
    'registerSuccess.couponCode': 'Coupon Code',
    'registerSuccess.parValue': 'Par Value',
    'registerSuccess.usingAmount': 'Min. Using <br>Amount:',
    'registerSuccess.periodValidity': 'Period of Validity',
    'registerSuccess.alreadyRegister':'We found that you have already registered by the email:<strong>{0}</strong> through which we\'ve offered coupon as new customer, so no coupon for this account,because the coupon is only for new customer, not for every new account. Thanks for your understanding.',
    'registerSuccess.nowYou': 'Now You can:',
    'registerSuccess.returnTo': 'Return to',
    'registerSuccess.goTo': 'Go to',
    'registerSuccess.previousPage': 'Previous Page',
    'registerSuccess.homePage':'Home Page',


    // deliveryFee
    'deliveryFee.chooseCountry':'Please choose your Country/Region then you can get the shipping cost.',
    'deliveryFee.countryRegion':'Country/Region:',
    'deliveryFee.selectCountry':'Please select your Country/Region.',
    'deliveryFee.displayMode':'Display Mode:',
    'deliveryFee.detailedModel':'Detailed Model',
    'deliveryFee.conciseModel':'Concise Model',
    'deliveryFee.shippingMethod':'Shipping Method:',
    'deliveryFee.workingDays': 'working days',

    'deliveryFee.weight': 'Weight',
    'deliveryFee.price': 'Price(USD)',

    // 加入购物车

    'addToCart.successTip' : "Add to cart successfully <p class='line'>{0} / {1} total: <b class='red'>{2}</b></p>",
    'addToCart.delayTip' : "Add to cart successfully  <p class='line'>Preparation time will be extanded for <b>3-5</b> days for purchase quantity exceeds inventory.</p><p class='line'>{0} / {1} total: <b class='red'>{2}</b></p>",
    'addToCart.largePackageTip' : "Add to cart successfully <p class='line'>Due to your purchasing quatity is large, please re-choose large batch item code to buy to share the competitive price</p><p class='line'>{0} / {1} total: <b style='color:#AF0F1E;'>{2}</b></p>",
    'addToCart.produceNotExistsError' : "This item has been off shelf.",
    'addToCart.productOutOfStockError' : "Sorry, product only have {0} units in stock, and you already have {1} units in your shopping cart.",
    'addToCart.unitsInStock' : "Sorry, product only have {0} units in stock.",
    'batchAddToCart.outOfStockTip': "The Product out of stock!",
    'addToCart.notFindProductTip': "Could not find product",
    'addToCart.addFaildTip': "Faild to add product to cart.",
    'batchAddToCart.productNoPriceError': "Something is wrong with the item price. Please contact us to solve it.",
    'addToCart.invalidNumber' : "Sorry that the number you input is invalid, please enter a valid positive integer.",
    'addToCart.quantityMustUp': "Purchase quantity must up to start quantity.",
    'addToCart.viewCart':'View Cart',
    'addToCart.close':'Close',

    "helpCenter.helpAsk.tips":"Tips",
    "helpCenter.helpAsk.serviceOnDuty":`{0} Customer Service teams are available at 9: 00AM-5: 00PM, from Monday to Saturday.
          <a href="http://www.thetimezoneconverter.com/" target="_blank">Click here</a> to convert it to your local time. Now, it is
          <strong>{1}(GMT +8)</strong>. Please leave your questions; we will give you the reply at our earliest convenience.`,
    "helpCenter.helpAsk.ticket":"Ticket",
    "helpCenter.helpAsk.successful":"Submission Successful!",
    "helpCenter.helpAsk.yourEmail":"Your E-mail",
    "helpCenter.helpAsk.emailValid":"We will reply to you by email, please make sure the email address is valid.",
    "helpCenter.helpAsk.content":"Content",
    "helpCenter.helpAsk.verificationCode":"Verification Code",
    "helpCenter.helpAsk.attachment":"( .gif | .png | .jpeg | .jpg | .doc | .bmp | .xls | .rar | .zip | .xlsx | .pdf ) The size of the attachment should be less than 2M !",
    "helpCenter.helpAsk.submit":"Submit",
    "helpCenter.helpAsk.reset":"Reset",
    "helpCenter.helpAsk.email":"Email",
    "helpCenter.helpAsk.address":"Address",
    "helpCenter.helpAsk.phone":"Phone",
    "helpCenter.helpAsk.views":"Views",
    "helpCenter.helpAsk.most":"Most viewed articles",
    "helpCenter.helpAsk.lastUpdated":"Last Updated",
    "helpCenter.helpAsk.latestArticles":"Latest articles",
    "helpCenter.helpAsk.term":"Term & Conditions",
    "helpCenter.helpAsk.contentMsg":"You must provide content.",
    "helpCenter.helpAsk.fileSizeMsg":"The size of the attachment should be less than 2M !",
    "helpCenter.helpAsk.fileTypeMsg":"We support .gif|.png|.jpeg|.jpg|.doc|.bmp|.xls|.rar|.zip|.xlsx|.pdf",

    "helpCenter.helpDetail.previous":"Previous",
    "helpCenter.helpDetail.next":"Next",

    "helpCenter.back":"Back",
    "helpCenter":"Help Center",
    "helpCenter.leftTree.contactUs":"Contact Us",
    "helpCenter.leftTree.haveQuestion":"Have a question",
    "helpCenter.leftTree.weCanHelp":"We can help",
    "helpCenter.leftTree.askQuestion":"Ask Question",

    "helpCenter.helpCategory.most":"Most Frequently Asked Questions",
    "helpCenter.helpCategory.more":"More",

    "helpCenter.helpSearch.result":'{0} - {1} of {2} Results for <b class="fontRed">"{3}"</b>',
    "helpCenter.helpSearch.noResult":'Not Found Results for  <b class="fontRed">"{0}"</b>',

    "helpCenter.helpSearch.searchHelpPages":'Search the help pages',
    "helpCenter.helpSearch.notSearchProducts":'Does not search for items or products',
    "helpCenter.helpSearch.search":'Search',


    //支付中心
    "center.orderDetails":"Order Details",
    "center.orderSummary":"Order Summary",
    "center.productCost":"Product Cost",
    "center.shippingHandlingFee":"Shipping & Handling Fee",
    "center.addtionalFee":"Addtional Fee",
    "center.couponRedemption":"Coupon Redemption",
    "center.replacementRedemption":"Replacement Redemption",
    "center.specialOffer":"Special Offer",
    "center.orderTotal":"Order Total",
    "center.totalPaid":"Total Paid",
    "center.helpInformation":"Help Information",
    "center.stillNeedtoPay":"Still Need to Pay",
    "center.paymentDetails":"Payment Details",

    //支付完成页
    "completed.WelcomeToTheLuckyDraw":"Welcome to the lucky draw!",
    "completed.congratualations":"Congratualations",
    "completed.got2Cash":"You have got a 2$ cash",
    "completed.got8Coupon":"You have got a $8 coupon",
    "completed.got3Off":"You have got a 3% off <br>discount coupon",
    "completed.got30Coupon":"You have got a $30 coupon",
    "completed.couponValidTime30":"Valid Time: 30 days after received",
    "completed.couponCode":"Coupon Code",
    "completed.tryAgain":"Let's try again",
    "completed.goForIt":"Go For it",
    "completed.thankParticipation":"Thank you for your participation",
    "completed.hopeYouWinNextTime":"Hope you will win prize next time",
    "completed.ruleDescription":"Rule Description",
    "completed.pleaseParticipate":"Please participate in the prize draw at this page, otherwise you will not find it anymore. Thank you.",
    "completed.anotherChance":"You've got only one chance to draw the prize. But if you draw the \"Try again\", you will get another chance to draw the prize.",
    "completed.drawInAccount":"If you draw the \"$8 coupon\", \"3% off discount coupon\", \"$2 cash\" and \"$30 Coupon\", the prize will be in your account immediately. You can use it for your next order payment.",
    "completed.thankYouForShopping":"Thank you for shopping with us",
    "completed.registeringEnjoyService":"<a style='color: #f94d5a;text-decoration: underline;' href=\"/Customer/SignIn\">Registering as {0} member</a> will enjoy better service and various preferential policies from now on.",
    "completed.offerPackingDividing":"{0} can offer Packing and Dividing Jewelry Findings,OEM processing and other services based on your requirements which can save much cost for you. Want to know the details? Please <a href=\"/HelpCenter/HelpDetail?articleId=11&categoryId=4\">click here</a>.",
    //支付方式
    "payMethod.neverAskChangeBeneficiary":"Please make sure your payment is paid to the above account, {0} will never ask customer to change the beneficiary account by email or any other ways.",
    "payMethod.noteCheckBeneficiaryName":"Please kindly note Check Beneficiary's name is \"<b> {0}</b>\", not {1}.",


    "myaccount.myAccount":"My Account",
    "myaccount.allOrders":"All Orders",
    "myaccount.pending":"Pending",
    "myaccount.preparing":"Preparing",
    "myaccount.prepared":"Prepared",
    "myaccount.packing":"Packing",
    "myaccount.sent":"Sent",
    "myaccount.finished":"Finished",
    "myaccount.cancelled":"Cancelled",
    "myaccount.paymentHistory":"Payment History",
    "myaccount.purchaseHistory":"Purchase History",
    "myaccount.recentItems":"Recently Purchased Items",
    "myaccount.cancelledItems":"Cancelled Items",
    "myaccount.personalization":"Personalization",
    "myaccount.accountSetting":"Account Setting",
    "myaccount.myWishList":"My Wish List",
    "myaccount.myShoppingCart":"My Shopping Cart",
    "myaccount.newsletter":"Newsletter",
    "myaccount.myReviews":"My Reviews",
    "myaccount.myFootprint":"My Footprint",
    "myaccount.ticketCenter":"Ticket Center",
    "myaccount.allTickets":"All Tickets",
    "myaccount.unreadTickets":"Unread Tickets",
    "myaccount.submitTicket":"Submit a Ticket",
    "myaccount.expireSoon":"Expire Soon",
    "myaccount.complaint":"Complaint",
    "myaccount.cashCoupon":"Cash & Coupon",
    "myaccount.cashAccount":"Cash Account",
    "myaccount.coupon":"Coupon",
    "myaccount.points":"Points",
    "myaccount.specialBargains": "Special Bargains For You",
    "myaccount.shippingDiscount": "Shipping Discount Only for You",
    "myaccount.onlyForYou":"Only For You",
    "myaccount.submit":"Submit",


    // newsletter
    "newsletter.notice":"Notice",
    "newsletter.privacy":"We value your privacy and never share your personal information or Email address with any third party company or organization.",
    "newsletter.sendEmail":"We will only send you 2 Emails weekly",
    "newsletter.subscribeNewsletter":"Please click the following button to subscribe our newsletter with the latest {0} news, offer, discount and new products.",
    "newsletter.regularNewsletter":"Regular Newsletter –Weekly new arrivals, hot sellers or promotion information.",
    "newsletter.couponNewsletter":"Coupon Newsletter - Keep you reminded about the coupon expiry date and new coupons available.",

    // accountSetting
    "accountSetting.deactivateAccount":"Deactivate Account",
    "accountSetting.myProfile":"My Profile",
    "accountSetting.changePassword":"Change Password",
    "accountSetting.changeEmail":"Change Email Address",
    "accountSetting.firstName":"First Name",
    "accountSetting.lastName":"Last Name",
    "accountSetting.passStrong":"Your password strength is too weak, Please reset your password.",
    "accountSetting.oldPass":"Old Password",
    "accountSetting.newPass":"New Password",
    "accountSetting.weak":"Weak",
    "accountSetting.medium":"Medium",
    "accountSetting.strong":"Strong",
    "accountSetting.confirmPass":"Confirm New Password",
    "accountSetting.newEmail":"New Email",
    "accountSetting.verificationCode":"Verification Code",
    'accountSetting.emailEdit.msg':'Your email address has been modified successfully.',
    'accountSetting.passEdit.msg':'Your password has been modified successfully.',
    'accountSetting.nameEdit.msg':'Your profile has been modified successfully.',

    // ticket
    "ticketCenter.allTickets":"All Tickets",
    "ticketCenter.unReadTickets":"Unread Tickets",
    "ticketCenter.selectCategory":"Select Category",
    "ticketCenter.advancedSearch":"Advanced Search",
    "ticketCenter.keyword":"Ticket Keyword",
    "ticketCenter.current": "Current",
    "ticketCenter.before": "Before",
    "ticketCenter.subject": "Subject",
    "ticketCenter.lastUpdate": "Last Update",
    "ticketCenter.attachment":"Attachment:",
    "ticketDetail.you":"You:",
    "ticketCenter.date":"Date:",
    "ticketCenter.reset":"Reset",
    "ticketCenter.noTicket":"No ticket was found.",
    "ticketCenter.subSuccessful":"Submission Successful!",

    // submit a ticket
    "ticketCenter.submitTicket":"Submit a Ticket",
    "ticketCenter.createTicket":"Create a new ticket",
    "ticketCenter.category":"Category:",
    "ticketCenter.createTime":"Create time:",
    "ticketCenter.content": "Content:",
    "ticketMsg.category":"Please select a category.",
    "ticketMsg.subject":"You must provide a subject.",
    "ticketMsg.content":"You must provide content.",
    "ticket.uploadImg":"( .gif | .png | .jpeg | .jpg | .doc | .bmp | .xls | .rar | .zip | .xlsx | .pdf )\n" +
        "                  The size of the attachment should be less than 2M ! ",

    "ticket.complaintNoticeHead":"Complain The Customer Service Staff" ,
    "ticket.complaintNoticeBody":"(Your complaint will be sent directly to our customer service manager, your exclusive customer service will not be able to see it.",


    // ticket detail
    "ticketDetail.ticketDetails":"Ticket Details",
    "ticketEdit.notice":"Notice:",
    "ticketEdit.complaint": "Complaint",
    "ticketEdit.complaintNotice":"Please if you are not satisfied with your client manager or our customer service, just submit a complaint. We have special team to deal with your complaint, and will reply to you at the earliest time",

    // coupon

    "coupon.title":"Coupon",
    "coupon.Notice":"Notice:",
    "coupon.explain":"You can use the active coupon to pay for the product cost when you check out. But please be noted that each coupon can be used for only one time , and also one order can be paid by only one coupon.",
    "coupon.Please":"Please",
    "coupon.addedSuccessFully":"Added successfully !",
    "coupon.CodeEnter":"Sorry, please enter a coupon code !",
    "coupon.codeAdded":"Sorry, you have already added this coupon !",
    "coupon.codeIncorrect":"Sorry, the coupon code is incorrect !",
    "coupon.codeEnd":"Sorry, the coupon activity has ended !",
    "coupon.codeCollected":"Usage Limit of this Coupon Code Has Been Reached !",
    "coupon.couponCode":"Coupon Code",
    "coupon.orderNo":"Order No.",
    "coupon.parValue":"Par Value",
    "coupon.minUsingAmount":"Min. Using Amount",
    "coupon.status":"Status",
    "coupon.periodOfValidity":"Period of Validity",
    "coupon.used":"Used",
    "coupon.expired":"Expired",
    "coupon.active":"Active",
    "coupon.inactive":"Inactive",
    "coupon.noCoupon":"There is no any coupon.",

    // points
    "points.title":"Points",
    "points.Notice":"Notice:",
    "points.explain":"Points can be applied toward to orders, and please convert them into coupon here first. In the steps of\n" +"payment, you can use coupon to pay for your order. Please be kindly noted that one order can be paid by one\n" + " coupon.",
    "points.howToEarnPoints":"How to earn points?",
    "points.Tips":"Tips: Ensure to enter a multiple of 5 scores",
    "points.insufficient":"Insufficient Points",
    "points.submitted":"Coupon has been presented, please check",
    "points.Myaccount":"My account",
    "points.Cash":"Cash",

    "points.parValue":"ParValue:",
    "points.minimumUsingAmount":"Minimum Using Amount: Goods Value ",
    "points.expiredDate":"Expired Date:",
    "points.clickHere":"Click here",
    "points.cashCoupon":" to know how to use Cash Coupon",
    "points.date":"Date",
    "points.income":"Income",
    "points.payout":"Payout",
    "points.description":"Description",

    // paymentHistory
    "paymentHistory.paymentHistory":"Payment History",
    "paymentHistory.advancedSearch":"Advanced Search",
    "paymentHistory.paymentMethod":"Payment Method",
    "paymentHistory.all":"All",
    "paymentHistory.orderNo":"Order No",
    "paymentHistory.date":"Date",
    "paymentHistory.reset":"Reset",
    "paymentHistory.paidAmount":"Paid Amount",
    "paymentHistory.amountAppliedOrder":"Amount Applied to Order",
    "paymentHistory.thereNoPaymentHistory":"There is no any payment history",


    "myaccount.myOrder":"My Orders",
    "myaccount.myOrder.itemCodeOrName":"Item Code/Name",
    "myaccount.myOrder.item":"Item",
    "myaccount.myOrder.reviews":"Reviews",
    "myaccount.myOrder.awaitingPayment":"Awaiting Payment",
    "myaccount.myOrder.needToSelectReplacement":"Need to Select Replacement",
    "myaccount.myOrder.yourOrderHasBeenCancelled":"Your order {0} has been cancelled",
    "myaccount.myOrder.orderNotice":"Order Notice",
    "myaccount.myOrder.thereIsNotAnyOrder":"There is not any order",
    "myaccount.myOrder.cashPayment":"Cash Payment",
    "myaccount.myOrder.readyRate":"Ready Rate",
    "myaccount.myOrder.allOrders":"All Orders",
    "myaccount.myOrder.confirmOrderReceived":"Confirm Order Received",
    "myaccount.myOrder.review":"Review",
    "myaccount.myOrder.oKIKnow":"OK, I Know",
    "myaccount.myOrder.close":"Close",
    "myaccount.myOrder.trackPackage1":"Track Package1",
    "myaccount.myOrder.trackOrder":"Track Order",
    "myaccount.myOrder.inTransitToTheShippingAgent(takes 1-2 days)":"In transit to the shipping agent (takes 1-2 days)",
    "myaccount.myOrder.trackPackage2":"Track Package2",
    "myaccount.myOrder.realInvoice":"Real Invoice",
    "myaccount.myOrder.customInvoice":"Custom Invoice",
    "myaccount.myOrder.makePayment":"Make Payment",
    "myaccount.myOrder.cancel":"Cancel",
    "myaccount.myOrder.reOrder":"Re-Order",
    "myaccount.myOrder.submitATicket":"Submit a Ticket",
    "myaccount.myOrder.viewOrder":"View Order",
    "myaccount.myOrder.orderReviewPrint": "Write a review for this order, you will get <font color=\"red\">{0}</font> cash points.",
    "myaccount.myOrder.notRead": "Sorry, the following orders are unready temporarily. You can click \"View Order\"to know the details.",
    "myaccount.myOrder.submitSuccess": "The request of combing delivery  has submitted successfully!",
    "myaccount.myOrder.orderDate": "Order Date",
    "myaccount.myOrder.orderStatus": "Order Status",
    "myaccount.myOrder.orderNumber": "Order Number",
    "myaccount.myOrder.twoOrders":"Sorry! Please select at least two orders!",
    "myaccount.myOrder.Confirm":"Confirm",
    "myaccount.myOrder.combine":"Do you really want to combine shipping?",
    "myaccount.myOrder.cannotCombine":"Sorry, the order with different shipping methods cannot be combined.",
    "myaccount.myOrder.shippedTogether":"Sorry, orders with different shipping address can not be shipped together!",
    "myaccount.myOrder.canceledIt":"In order to improve our services to you, please tell us why you canceled it:",
    "myaccount.myOrder.reason":"Please select your reason",
    "myaccount.myOrder.characters":"Please do not exceed 200 characters",
    "myaccount.myOrder.anyMore":"I don't want it any more",
    "myaccount.myOrder.FalseOrder":"False or repetitive order",
    "myaccount.myOrder.High":"High price of the item",
    "myaccount.myOrder.cost":"High delivery cost",
    "myaccount.myOrder.changing":"Delivery address or method needs changing",
    "myaccount.myOrder.Paymentfailed":"Payment failed",
    "myaccount.myOrder.ItemExchange":"Item exchange or new item adding",
    "myaccount.myOrder.Other":"Other:",
    "myaccount.myOrder.Dispatch":"Estimate Dispatch Date",
    "myaccount.myOrder.Package1":"Package1",
    "myaccount.myOrder.Replacement":"Replacement",
    "myaccount.myOrder.OrderNo":"Order No",
    "myaccount.myOrder.Status":"Status",
    "myaccount.myOrder.OrderTotal":"Order Total",
    "myaccount.myOrder.TotalPaid":"Total Paid",
    "myaccount.myOrder.toCustoms":"Value Declared to Customs",
    "myaccount.myOrder.Action":"Action",
    "myaccount.myOrder.check":"Check",
    "myaccount.myOrder.to":"To",
    "myaccount.myOrder.package2":"Package2",
    "myaccount.myOrder.yes":"Yes",
    "myaccount.myOrder.no":"no",
    "myaccount.myFootprint.category":"Category",
    "myaccount.myFootprint.all":"--All--",
    "myaccount.myFootprint.allCate":"--All Categories--",
    "myaccount.myOrder.received":"Can we change your order status to received?",
    "myaccount.myOrder.confirm":"please confirm if you want to change the package status to \"Signed for\"",
    "myaccount.myOrder.wantTo":"Do you want to download real invoice of all combined orders?",
    "myaccount.myOrder.Rate":"Ready Rate:100%",
    "myaccount.myOrder.tryAgain":"This order can't be cancelled. You can refresh the page and try again.\r\nIf any need, please contact Customer Service.",

    "myaccount.addressBook":"Address Book",
    "myaccount.addressBook.notice":"Notice",
    "myaccount.addressBook.noticeText":"Credit card companies require that your billing address on an order be the same as the billing address registered with them.",
    "myaccount.addressBook.address":"Address",
    "myaccount.addressBook.defaultShippingAddress":"Default Shipping Address",
    "myaccount.addressBook.defaultBillingAddress":"Default Billing Address",
    "myaccount.addressBook.enterNewAddress":"Enter a new address",

    "myaccount.addressBook.companyName":"Company name",
    "myaccount.addressBook.phoneNumber":"Phone number",
    "myaccount.addressBook.addressLine1":"Address line1",
    "myaccount.addressBook.addressLine2":"Address line2",
    "myaccount.addressBook.country":"Country/Region",
    "myaccount.addressBook.state":"State/Province",
    "myaccount.addressBook.city":"City/Town",
    "myaccount.addressBook.zip":"ZIP/Postal code",
    "myaccount.addressBook.mobile":"Mobile",
    "myaccount.addressBook.acceptMessage":"I want to receive message about order status",
    "myaccount.addressBook.saveShippingAddress":"Save as my Shipping Address",
    "myaccount.addressBook.saveBillingAddress":"Save as my Billing Address",

    "myaccount.addressBook.enterEnglish":"Please enter English if possible..",
    "myaccount.addressBook.companyNameMsg":"Please enter your Company name with no more than 35 characters.",
    "myaccount.addressBook.fullNameMsg":"The total number of characters in First name and Last name cannot exceed 34 characters.",
    "myaccount.addressBook.phoneMsg":'Please enter your Phone Number with no more than 30 characters.',
    "myaccount.addressBook.streetMsg":"Please enter your Address with no more than 100 characters.",
    "myaccount.addressBook.countryMsg":"Please select your Country/Region.",
    "myaccount.addressBook.cityMsg":"Please enter your city/town with no more than 30 characters.",
    "myaccount.addressBook.cityFormatMsg":"Please don't include arabic numbers in city name.",
    "myaccount.addressBook.stateMsg":"Please enter your State/Province.",
    "myaccount.addressBook.zipMsg":"Please enter your ZIP/postal code with no more than 10 characters.",
    "myaccount.addressBook.zipFormatMsg":"Please enter Latin letters/numbers/spaces/hyphens only, and it must be less than 10 characters.",
    "myaccount.addressBook.faxMsg":"Please enter your mobile.",

    "myaccount.edit":"Edit",
    "myaccount.delete":"Delete",
    "myaccount.save":"Save",
    "myaccount.cancel":"Cancel",

    //myWishList
    "myWishList.increases.function":`My Wish List increases a new function of self-created sub wish lists to help easily classify and manage items.“{0} Wish List” is a default list where items already added can be found.`,
    "myWishList.myWishList":"My Wish List",
    "myWishList.items":"items",
    "myWishList.listName":"List Name",
    "myWishList.listDescription":"List Description",
    "myWishList.createList":"Create a List",

    //myWishListDetail
    "myWishListDetail.emptyList":"What’s a pity! This is an empty wish list.",
    "myWishListDetail.advancedSearch":"Advanced Search:",
    "myWishListDetail.addProductNow":"Add some product now",
    "myWishListDetail.category":"Category",
    "myWishListDetail.addDate":"Add Date",
    "myWishListDetail.codeOrName":"Item Code/Name:",
    "myWishListDetail.3day":"3 Days(Ready Time)",
    "myWishListDetail.reset":"Reset",
    "myWishListDetail.check":"Check",
    "myWishListDetail.to":"To",
    "myWishListDetail.item":"Item",
    "myWishListDetail.newest":"Newest",
    "myWishListDetail.price":"Price",
    "myWishListDetail.unavailable":"Unavailable",
    "myWishListDetail.add":"Add",
    "myWishListDetail.similarItems":"Similar Items",
    "myWishListDetail.deleteItem":"Delete Item",
    "myWishListDetail.moveToAnotherList":"Move to Another List",
    "myWishListDetail.emptyWishList":"Empty Wish List",
    "myWishListDetail.isConfirmDelete":"Do you confirm to delete it?",
    "myWishListDetail.pleaseEnteryourListName.":"Please enter your list name.",
    "myWishListDetail.listNameAlreadyExists":"Sorry! A list with this name already exists.",
    "myWishListDetail.allowOtherView":"Allow other view this list",

    // OnlyForYou
    "onlyForYou":'Only For You',

    //specialBargainsForYou
    "specialBargainsForYou":"Special Bargains for You",
    "specialBargainsForYou.advancedSearch":"Advanced Search:",
    "specialBargainsForYou.category":"Category:",
    "specialBargainsForYou.itemCode":"Item Code:",
    "specialBargainsForYou.discount":"Discount:",
    "specialBargainsForYou.3day":"3 Days(Ready Time)",
    "specialBargainsForYou.reset":"Reset",
    "specialBargainsForYou.check":"Check",
    "specialBargainsForYou.to":"To",
    "specialBargainsForYou.endDate":"End Date",
    "specialBargainsForYou.unavailable":"Unavailable",
    "specialBargainsForYou.add":"Add",
    "specialBargainsForYou.allCategories":"-- All Categories --",

    //cancelledItems
    "cancelledItems.cancelledItem":"Cancelled item",
    "cancelledItems.notice":"Notice:",
    "cancelledItems.showStockInfoOfPreviousOrders":`Just show you the real-time stock info of the cancelled item in your previous orders.If you still want them, please place order as soon as possible. You can select "in-stock Notice" for the unavailable item so that we can inform you in time when they are in stock again.`,
    "cancelledItems.advancedSearch":"Advanced Search:",
    "cancelledItems.category":"Category:",
    "cancelledItems.codeorName":"Item Code/Name:",
    "cancelledItems.inStock":"In Stock",
    "cancelledItems.reset":"Reset",
    "cancelledItems.check":"Check",
    "cancelledItems.to":"To",
    "cancelledItems.item":"item",
    "cancelledItems.stockInfo":"Stock Info",
    "cancelledItems.unavailable":"Unavailable",
    "cancelledItems.allCategories":"-- All Categories --",

    //purchaseHistory
    "purchaseHistory":"Purchase History",
    "purchaseHistory.advancedSearch":"Advanced Search:",
    "purchaseHistory.category":"Category:",
    "purchaseHistory.codeorName":"Item Code/Name:",
    "purchaseHistory.inStock":"In Stock",
    "purchaseHistory.reset":"Reset",
    "purchaseHistory.orderDate":"Order Date:",
    "purchaseHistory.check":"Check",
    "purchaseHistory.to":"To",
    "purchaseHistory.item":"Item",
    "purchaseHistory.lastOrderDate":"Last Order Date",
    "purchaseHistory.unavailable":"Unavailable",
    "purchaseHistory.addtoWishList":"Add to Wish List",
    "purchaseHistory.thereIsNoAnyPurchaseHistory":"There is no any purchase history.",
    "purchaseHistory.allCategories":"-- All Categories --",

    //onlyForYou
    "onlyforyou.allCategories":"-- All Categories --",
    "onlyforyou.advancedSearch":"Advanced Search:",
    "onlyforyou.category":"Category:",
    "onlyforyou.itemCode":"Item Code:",
    "onlyforyou.addDate":"Add Date",
    "onlyforyou.reset":"Reset",
    "onlyforyou.check":"Check",
    "onlyforyou.to":"To",
    "onlyforyou.add":"Add",

    //cashAccount
    "cashAccount.cashInfo":`When you have a credit in cash account, you can use it toward any orders with outstanding amount. In the payment step,
        our system will remind you to use the cash balance to pay, yes is the default choice.
        Just go ahead to complete the payment and your credit will be applied for your order.`,
    "cashAccount.balance":"Balance",
    "cashAccount.withdrawBalance":"Withdraw the balance by the original payment method",
    "cashAccount.depositForOrders":"Deposit for your orders in the future",
    "cashAccount.depositSuccessfullyTip":"Deposit successfully！We will update the Cash Account in 24 hours after you made the payment.",
    "cashAccount.receivedRefund":"We have received your refund request, our customer service team will arrange the refund soon.",
    "cashAccount.date":"Date",
    "cashAccount.income":" Income",
    "cashAccount.Payout":"Payout",
    "cashAccount.Description":"Description",

    //支付方式
    "payMethod.neverAskChangeBeneficiary2":"· In order to help us identify your payment more easily, please be sure to note your {0} registered email address when making remittance.",
    "payMethod.neverAskChangeBeneficiary3":"· <span style='color: #AF0F1E'>According to the banking requirements, we can only accept remittance from company accounts, not personal accounts.</span> If in doubt, please feel free to <a style='text-decoration: underline; color: #90bfb6;' target='_blank' href=\"/HelpCenter/HelpAsk\">contact us</a>.",
    "payMethod.neverAskChangeBeneficiary4":"· <span style='color: #AF0F1E'>According to the banking requirements, we can only accept remittance from company accounts, not personal accounts.</span> If in doubt, please feel free to <a href=\"/HelpCenter/HelpAsk\">contact us</a>.",
    "payMethod.neverAskChangeBeneficiary5":"· If in doubt, please feel free to <a style='text-decoration: underline; color: #90bfb6;' target='_blank' href=\"/HelpCenter/HelpAsk\">contact us</a>.",
    "payMethod.neverAskChangeBeneficiary6":"· If in doubt, please feel free to <a href=\"/HelpCenter/HelpAsk\">contact us</a>.",


};

export default en;
import {DeleteMapping, GetMapping, PostMapping, RequestBody, RequestMapping2Class, RequestParam} from "@/http";
import PageData from "@/service/model/PageData";
import WishListDirDto from "@/service/model/myaccount/WishListDirDto";
import WishListDetailDirParam from "@/service/model/myaccount/WishListDetailDirParam";
import WishListDetailDirDto from "@/service/model/myaccount/WishListDetailDirDto";
import AddToWishListDto from "@/service/model/myaccount/AddToWishListDto";


@RequestMapping2Class("/wishList")
export class WishListController {
    @GetMapping("/wishListDirHome")
    wishListDirHome(@RequestParam("currentPage") currentPage: number): Promise<PageData<WishListDirDto>> {
        throw 1;
    }

    @PostMapping("/createWishListDir")
    createWishListDir(@RequestParam("dirName") dirName:string, @RequestParam("description") description:string): Promise<PageData<WishListDirDto>> {
        throw 1;
    }

    @PostMapping("/wishListDirDetail")
    wishListDirDetail(@RequestBody param: WishListDetailDirParam): Promise<WishListDetailDirDto> {
        throw 1;
    }

    @PostMapping("/editWishListDir")
    editWishListDir(@RequestParam("wishListDirId")wishListDirId:number,
                    @RequestParam("dirName")dirName:string,
                    @RequestParam("dirDesc")dirDesc:string,
                    @RequestParam("isPublic")isPublic:boolean){throw 1;}

    @DeleteMapping("/delWishListDir")
    delWishListDir(@RequestParam("wishListDirId") wishListDirId: number): void {
        throw 1;
    }

    @PostMapping("/deleteProductFromWishList")
    deleteProductFromWishList(@RequestBody productIds:number[]):void {
        throw 1;
    }

    @GetMapping("/wishListDirList")
    wishListDirList( @RequestParam("exceptId")exceptId:number):Promise<WishListDirDto[]>{
        throw 1;
    }

    @PostMapping("/addToWishList")
    addToWishList(@RequestParam("productIds")productIds:number[],@RequestParam("wishListDirId") wishListDirId:number): Promise<AddToWishListDto>{
        throw 1;
    }

    @PostMapping("/emptyWishList")
    emptyWishList(@RequestParam("wishListDirId") wishListDirId:number): void{
        throw 1;
    }
}

const wishListController = new WishListController();
export { wishListController };
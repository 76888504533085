const emails: String[] = [
  "yahoo.com",
  "gmail.com",
  "hotmail.com",
  "aol.com",
  "comcast.net",
  "msn.com",
  "hotmail.co.uk",
  "yahoo.co.uk",
  "sbcglobal.net",
  "live.com",
  "btinternet.com",
  "verizon.net",
  "att.net",
  "bellsouth.net",
  "cox.net",
  "ymail.com",
  "bigpond.com",
  "mail.ru",
  "live.co.uk",
  "hotmail.fr",
  "googlemail.com",
  "charter.net",
  "ntlworld.com",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "sky.com",
  "earthlink.net",
  "rocketmail.com",
  "tiscali.co.uk",
  "yahoo.com.au",
  "yahoo.ca",
  "optusnet.com.au",
  "shaw.ca",
  "blueyonder.co.uk",
  "talktalk.net",
  "free.fr",
  "mac.com",
  "me.com",
  "xtra.co.nz",
  "embarqmail.com",
  "optonline.net",
  "libero.it",
  "yahoo.es",
  "windstream.net",
  "q.com",
  "juno.com",
  "yandex.ru",
  "bigpond.net.au",
  "163.com",
  "hotmail.it",
  "rogers.com",
  "itecheasy.com",
  "sympatico.ca",
  "live.com.au",
  "web.de",
  "roadrunner.com",
  "inbox.lv",
  "gmx.de",
  "mail.com",
  "telus.net",
  "neuf.fr",
  "yahoo.gr",
  "frontiernet.net",
  "live.ca",
  "yahoo.it",
  "videotron.ca",
  "suddenlink.net",
  "aim.com",
  "netzero.com",
  "tampabay.rr.com",
  "frontier.com",
  "fsmail.net",
  "virgin.net",
  "laposte.net",
  "telia.com",
  "eircom.net",
  "hughes.net",
  "seznam.cz",
  "cableone.net",
  "centurytel.net",
  "cfl.rr.com",
  "btopenworld.com",
  "peoplepc.com",
  "hotmail.es",
  "yahoo.com.sg",
  "yahoo.de",
  "t-online.de",
  "netzero.net",
  "wp.pl",
  "bigpond.com.au",
  "abv.bg",
  "hotmail.ca",
  "qq.com",
  "online.no",
  "live.fr",
  "mchsi.com",
  "bluewin.ch",
  "club-internet.fr",
  "insightbb.com",
  "btconnect.com",
  "alice.it",
  "rambler.ru",
  "live.nl",
  "westnet.com.au",
  "yahoo.com.mx",
  "cytanet.com.cy",
  "tesco.net",
  "centurylink.net",
  "netscape.net",
  "126.com",
  "tds.net",
  "aapt.net.au",
  "aliceadsl.fr",
  "aol.co.uk",
  "virginmedia.com",
  "iinet.net.au",
  "hawaii.rr.com",
  "iprimus.com.au",
  "wildblue.net",
  "live.se",
  "bk.ru",
  "virgilio.it",
  "hot.ee",
  "wi.rr.com",
  "tpg.com.au",
  "tiscali.it",
  "YAHOO.CO.IN",
  "internode.on.net",
  "cogeco.ca",
  "ukr.net",
  "gmx.com",
  "telenet.be",
  "sfr.fr",
  "voila.fr",
  "mail.dk",
  "excite.com",
  "mindspring.com",
  "inbox.ru",
  "talk21.com",
  "bresnan.net",
  "sapo.pt",
  "coqui.net",
  "wowway.com",
  "nycap.rr.com",
  "rochester.rr.com",
  "freenet.de",
  "austin.rr.com",
  "ptd.net",
  "nc.rr.com",
  "email.com",
  "clear.net.nz",
  "fuse.net",
  "BELL.NET",
  "gmx.at",
  "carolina.rr.com",
  "yahoo.dk",
  "yahoo.co.nz",
  "cegetel.net",
  "pacbell.net",
  "yahoo.ie",
  "luukku.com",
  "inbox.com",
  "prtc.net",
  "gmx.net",
  "twcny.rr.com",
  "list.ru",
  "o2.pl",
  "live.dk",
  "hotmail.de",
  "y7mail.com",
  "swbell.net",
  "telkomsa.net",
  "zoominternet.net",
  "o2.co.uk",
  "supanet.com",
  "live.it",
  "arcor.de",
  "yahoo.se",
  "windowslive.com",
  "eastlink.ca",
  "ameritech.net",
  "myfairpoint.net",
  "satx.rr.com",
  "mweb.co.za",
  "columbus.rr.com",
  "cinci.rr.com",
  "planet.nl",
  "dodo.com.au",
  "adelphia.net",
  "sasktel.net",
  "yahoo.co.jp",
  "cs.com",
  "tx.rr.com",
  "clearwire.net",
  "skynet.be",
  "Prodigy.net",
  "hotmail.com.au",
  "gmx.ch",
  "terra.es"
]

export default emails;
import App from './layouts/Index.vue'
import createRouter from "./router"
import { createHead } from "unhead"
import { createSSRApp } from 'vue'

import {messageInit} from "@/lang/message";
// @ts-ignore
import {errorHandler,sendLogToServer} from "@/ErrorProcess";
import ClientOnly from "@/components/common/clientOnly/ClientOnly.vue";
import * as Sentry from "@sentry/vue"

export default function() {
    // 加载翻译
    messageInit('en')

    const app = createSSRApp(App)

    if (import.meta.env.SSR) {

    }

    if (typeof window !== 'undefined') {
        // 捕获未处理的Promise拒绝
        window.addEventListener('unhandledrejection', event => {
            sendLogToServer({
                message: event?.reason?.message,
                stack: event?.reason?.stack,
                url: window.location.href
            })
        })

        // window.onerror 捕获其他 JavaScript 错误
        window.onerror = function (message, source, lineno, colno, error) {
            sendLogToServer({
                message: message,
                source: source,
                lineno: lineno,
                colno: colno,
                stack: error ? error.stack : null,
                url: window.location.href
            })
        }
    }

    const router = createRouter()
    const head: any = createHead()
    app.use(head)
    app.use(router)
    app.component('client-only', ClientOnly)

    // Vue 组件错误处理
    app.config.errorHandler = (error, instance, info) => {
        // 使用 Sentry 捕获 Vue 组件的错误
        Sentry.captureException(error)
        errorHandler(error, instance, info) // 调用你的自定义错误处理逻辑
    }

    return { app, router, head }
}

<template>
  <div class="head clearfix hidden-sm hidden-md">
    <div class="recurrence" v-show="isShowGoBack" @click="goBack"><a href="javascript:;"></a></div>
    <div class="category drawer" @click="handleShowLeftMenu"></div>
    <div class="logo">
      <a href="/" aria-label="cobeads"></a>
    </div>
    <div class="header-r header-r01 cls">
      <a class="footprint fl" title="footprint" href="/MyAccount/myFootPrint"></a>
      <a href="javascript:;" title="search" class="search" @click="handleSearch"></a>
      <a title="shopping cart" href="/ShoppingCart-ShoppingCart-1" class="shoppingCart">
        <span class="itemsNum jsSpnCartCount">{{headCustomerInfo.cartItemCount}}</span>
      </a>
      <a title="my account" href="/MyAccount" class="debarkation"></a>
    </div>
  </div>
  <!-- 只在客户端加载 -->
  <client-only>
    <PhoSeaPhoto :is-show-phone-search="isShowPhoSearch" @handleClose="handleClose"/>
    <LeftMenu :is-show-left-menu="isShowLeftMenu" @handleCloseMenu="handleCloseMenu"/>
  </client-only>
</template>

<script lang="ts" src="./PhoneTop.ts"></script>

<style scoped src="./phoneTop.less" lang="less"></style>